@import "../styles/settings/RBCC-theme-variables";

$rb-dark-gray: #3C3C3C;
$rb-dark-gray-background-light-accent-color: #666;
$rb-gray: #666;
$rb-gray-background-dark-accent-color: #555;
$rb-gray-disabled-text-color: #aaa;

$rb-main-frame-background-color: $rb-dark-gray;
$rb-main-play-menu-background-color: $rb-gray;
$rb-main-menu-button-disabled-color: $rb-gray-disabled-text-color;
$rb-main-menu-button-group-separator-color: $rb-gray-background-dark-accent-color;

$rb-left-sidebar-menu-button-group-separator-color: $rb-dark-gray-background-light-accent-color;

$rb-mobile-min-button-width: 72px;
$rb-mobile-play-menu-background-color: $rb-dark-gray;
$rb-mobile-play-menu-border-color: #3d3d3d;

$rb-percent-darker-for-hover: 3%;
$rb-padding-between-toolbar-buttons: 2px;
$rb-padding-between-toolbar-button-groups: 4px;

//
// TEMPORARY - FOR APPLES TO APPLES COMPARISON
.main-app-container:not(.rb-flat-theme) {
	.player-menu {
		.player-menu-sub-container {
			ul {
				li {
					.icon-button-container {
						.mat-icon-button {
							width: auto;
						}
					}
				}
			}
		}
	}

	.player-menu-mobile {
		ul {
			li {
				.mat-icon-button {
					width: auto;
				}
			}
		}
	}
}

// =============================================================================================================================================================
// Flat UI Style
// =============================================================================================================================================================
.rb-flat-theme {

	&.main-app-container {

		// Override grid cell selection border
		.ag-theme-material {
			.ag-ltr {
				.ag-has-focus {
					.ag-cell-focus:not(.ag-cell-range-selected) {
						border: 1px solid transparent;
					}
				}
			}
		}

		.mat-drawer-container {
			background-color: $rb-main-frame-background-color;

			.top-nav--secondary {
				box-shadow: none;
			}

			// -------------------------------------------------------------------------------------------------------------------------------------------------
			// Tri-Pane Styles
			// -------------------------------------------------------------------------------------------------------------------------------------------------
			.tri-pane-container {

				// ---------------------------------------------------------------------------------------------------------------------------------------------
				// Common Toolbar Styles
				// ---------------------------------------------------------------------------------------------------------------------------------------------
				.player-menu {
					.player-menu-sub-container {
						display: flex;

						ul { // Toolbar Button Group
							display: flex;
							color: white;
							box-shadow: none;
							margin: 0;
							list-style-type: none;
							padding: 8px $rb-padding-between-toolbar-button-groups 10px;

							&.export-csv {
								position: absolute;
								right: 0;
							}

							li {
								padding: 0 $rb-padding-between-toolbar-buttons;

								.icon-button-container {
									padding: 0;

									.mat-icon-button {
										width: auto;
										min-width: 40px;
										padding: 0 2px;
									}
								}
							}
						}

						ul:first-child {
							padding-left: 0;
						}

					}
				}

				// ---------------------------------------------------------------------------------------------------------------------------------------------
				// Common Mobile Toolbar Styles
				// ---------------------------------------------------------------------------------------------------------------------------------------------
				.player-menu-mobile {
					width: 100%;
					margin: 0;
					padding: 0;
					background-color: $rb-mobile-play-menu-background-color;
					color: white;

					.player-menu-mobile-sub-container {
						display: flex;
						justify-content: center;

						&.mobile-overflow-menu {
							flex-wrap: wrap;

							.extra-menu-mobile-container {
								border-bottom: 1px solid $rb-mobile-play-menu-border-color;
							}
							ul:not(:last-of-type) {
								li {
									border-top: 1px solid $rb-mobile-play-menu-border-color;

									&:last-child {
										border-right: 1px solid $rb-mobile-play-menu-border-color;
									}
								}
							}
						}

						ul {
							display: flex;
							flex-wrap: nowrap;
							border-left: 1px solid $rb-mobile-play-menu-border-color;

							&:last-of-type {
								border-right: 1px solid $rb-mobile-play-menu-border-color;
							}

							li {
								padding: 0 $rb-padding-between-toolbar-buttons;

								&:not(:first-child) {
									border-left: 1px solid $rb-mobile-play-menu-border-color;
								}

								.icon-button-container {
									padding: 0;

									.mat-icon-button {
										width: auto;
										min-width: calc(#{$rb-mobile-min-button-width} - #{$rb-padding-between-toolbar-buttons * 2});
										padding: 0 2px;
										border-radius: 0;		// Default radius (50%) clips long text values when dynamically sizing button widths.

									}
								}
							}

						}
					}
				}

				// ---------------------------------------------------------------------------------------------------------------------------------------------
				// Tri-Pane Left Pane Styles
				// ---------------------------------------------------------------------------------------------------------------------------------------------
				.left-pane {
					.player-menu { // Detail Panel (left sidebar) Toolbar
						.player-menu-sub-container {
							ul { // Toolbar Button Group
								background-color: $rb-dark-gray;
								border-right: 1px solid $rb-left-sidebar-menu-button-group-separator-color;

								.icon-button-container {
									.mat-icon-button {
										&:hover:not(:disabled) {
											background-color: darken($rb-dark-gray, $rb-percent-darker-for-hover);
										}
									}
								}
							}

							ul:last-child {
								border-right: none;
								padding-right: 0;
							}

						}
					}

					rb-controller-differences-left-sidebar {
						.item-selection-container {
							border: none !important;
						}
					}

					rb-controller-differences-left-sidebar {
						.item-selection-container {
							border: none !important;
						}
					}
				}

				// ---------------------------------------------------------------------------------------------------------------------------------------------
				// Tri-Pane Middle Pane Styles
				// ---------------------------------------------------------------------------------------------------------------------------------------------
				.middle-pane {
					.play-menu { // Main Panel Toolbar Panel (container)
						background-color: $rb-main-play-menu-background-color;

						.player-menu {
							.player-menu-sub-container {
								ul { // Toolbar Button Group
									background-color: $rb-main-play-menu-background-color;
									border-right: 1px solid $rb-main-menu-button-group-separator-color;

									.icon-button-container {
										.mat-icon-button {
											&:hover:not(:disabled) {
												background-color: darken($rb-main-play-menu-background-color, $rb-percent-darker-for-hover);
											}

											&:disabled {
												color: $rb-main-menu-button-disabled-color;
											}
										}
									}
								}

								ul:last-child {
									border-right: none;
									padding-right: 0;
								}

							}
						}
					}

					.rb-dry-run-toolbar,
					.rb-active-flow-toolbar,
					.rb-map-toolbar {

						.view-type-selection-container,
						#date-selection-container {

							button,
							#date-range-container {
								&:hover:not(:disabled) {
									background-color: darken($rb-main-play-menu-background-color, $rb-percent-darker-for-hover);
									border-radius: 0;
								}

								&:disabled {
									color: $rb-main-menu-button-disabled-color;
								}

								.mat-select-value,
								.mat-select-arrow {
									color: white;
								}
							}

							#date-range-container {
								&:active:not(:disabled) {
									background-color: darken($rb-main-play-menu-background-color, $rb-percent-darker-for-hover * 2);
								}
							}
						}

						#active-flow-toolbar-container {

							#active-flow-activity-type-selector {
								display: flex;
								align-items: center;
								border-right: 1px solid $rb-main-menu-button-group-separator-color;

								.mat-form-field {
									padding: 0 5px;

									&:hover:not(:disabled) {
										background-color: darken($rb-main-play-menu-background-color, $rb-percent-darker-for-hover);
										border-radius: 0;
									}

									.mat-form-field-wrapper {
										padding: unset;

										.mat-form-field-label-wrapper {
											display: none;
										}

										.mat-form-field-infix {
											border: none;

											mat-select {
												.mat-select-value,
												.mat-select-arrow {
													color: white;
												}

												&.mat-select-disabled {
													.mat-select-value,
													.mat-select-arrow {
														color: $rb-main-menu-button-disabled-color;
													}
												}
											}
										}

										.mat-form-field-underline {
											display: none;
										}
									}

								}

							}

							&.aft-mobile {
								flex: 1;

								#active-flow-activity-type-selector {
									flex: 1;
									padding: 0 5px;
								}
							}

						}
					}

					.rb-table-wrapper {
						.item-selection-container {
							background-color: $rb-dark-gray;

							@include for-size('tablet', max) {
								background-color: $rb-gray;
							}
						}
					}
				}
			}

		}

	}
}

