@import "../../styles/settings/RBCC-calc-variables";

$ag-grid-flash-color: rgba(128, 181, 121, 0.3);
$active-station-grid-cell-color: #008658;
//$grid-row-custom-cursor: url('~/assets/cursors/pencil.cur'), default;
$grid-row-custom-cursor: default;

.ag-grid-angular {
	flex: 1 1 auto;
	width: 100%;
	height: 0;
}

.rb-table-wrapper-grid-container {
	height: calc(100vh - #{$tp-vertical-grid-offset});
	justify-content: flex-end;
	background-color: white;
	position: relative;
}

.mat-dialog-container {
	.rb-table-wrapper-grid-container {
		height: calc(65vh);

		.ag-grid-angular {
			height: 100%;
			width: 100%;

			@include for-size('phone', max) {
				.ag-body {
					// I don't like using !important, but this allows the grid contents to fill the entire height of the grid.
					height: 100% !important;
				}
			}
		}
	}
}

// Override the (pink) default from ag-grid v21. Use the Rain Bird primary green.
//.ag-theme-material .ag-icon-checkbox-checked {
//	color: $primary;
//}

//Override the (pink) default from ag-grid v27. Use the Rain Bird primary green.
.ag-theme-material {
	.ag-checkbox-input-wrapper {
		&.ag-checked {
			&::after {
				color: $primary;
			}
		}
	}
}

// Grid Flash / Highlight Color
.ag-row .ag-cell-data-changed {
	background-color: $ag-grid-flash-color !important;
}

rb-station-status-cell-renderer,
rb-program-status-cell-renderer,
rb-station-mobile-cell-renderer,
rb-program-mobile-cell-renderer,
rb-program-step-mobile-cell-renderer,
rb-active-flow-status-cell-renderer,
rb-active-flow-runtime-cell-renderer,
rb-irrigation-queue-mobile-cell-renderer,
.cv-station-container {
	.station-status-running {
		color: $active-station-grid-cell-color;

		.station-status-cycling {
			color: #4a90e2;
		}

		.station-status-soaking {
			color: #555;
		}

		.station-status-locked {
			color:  #b9121b;
		}
	}
}

.icon-pump-style {
	font-size: 20px;
	color: #f1ac3b;

	&.booster {
		color: #f57336;
	}
}

.icon-pipe-style {
	font-size: 20px;
	color: #80b579;
}

.icon-flo-zone-style {
	font-size: 20px;
	color: #4a90e2;
}

.icon-more-edit-flow-element {
	border: 0em;
	margin-top: 15px;
	background: transparent;
}
.icon-station-connect-style {
	color: #80b579;
	font-size: 18px;
	-webkit-text-stroke-width: thick;
}

.icon-station-error-style {
	color: #b9121b;
	font-size: 18px;
	-webkit-text-stroke-width: thick;
}

.icon-station-suspended-style {
	color: darkorange;
	font-size: 16px;
}
.icon-station-connected-style {
	color: #80b579;
	font-size: 18px;
	text-align: left;
}

.icon-station-disconnected-style {
	color: #b9121b;
	font-size: 18px;
	text-align: left;
}

.icon-primary-style, .icon-warning-style, .icon-alert-style {
	font-size: 18px;
	text-align: left;
}

.icon-primary-style {
	color: #80b579;
}

.icon-warning-style {
	color: darkorange;
}

.icon-alert-style {
	color: #b9121b;
}

.icon-station-et {
	font-size: 22px;
}

// Suppress default grid row hover behavior. Show 'edit' icon for row; default icon for checkbox.
.ag-center-cols-container {
	cursor: $grid-row-custom-cursor;

	.ag-row {
		.ag-cell {
			white-space: normal;
			word-break: break-word;
			line-height: 16px;
			display: flex;
			align-items: center;

			cursor: $grid-row-custom-cursor;

			&.cellStyleCheckbox {
				cursor: default;
			}

			rb-hyperlink-cell-renderer {
				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			// ag-grid v27
			&.mobile {
				align-items: flex-start;
			}
		}
	}
}

// Editable Grid

.rb-editable-grid-container {
	.ag-theme-material {
		max-width: 100%;
		max-height: 100%;

		.ag-row {
			// TODO: ag-grid migration
			height: 48px !important;
		}

		.ag-cell {
			.rb-eg-fr-units {
				font-size: 11px;
				color: #666;
				width: 40px;
			}

			&.ag-cell-inline-editing {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.ag-cell-edit-input {
					padding-bottom: 0;
				}

				.rb-cell-editor-container {
					display: flex;
					align-items: center;

					input {
						background-color: transparent;
						border: 1px dotted lightgray;
						border-radius: 4px;
						padding: 5px;

						&.text-align-right {
							text-align: right;
						}

						&.rb-duration-cell {
							text-align: center;
							padding: 0;
							width: 18px;
							border: none;
						}
					}

					.rb-eg-fr-units {
						margin-left: 3px;
					}
				}
			}

			.rb-eg-fr-cell-container {
				display: flex;
				justify-content: flex-end;

				.rb-eg-fr-units {
					width: 36px;
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		.ag-tooltip {
			background-color: white;
		}
	}
}

// Table Editors

.table-editor-dlg-wrapper {
	.mat-dialog-container {
		position: relative;
	}
}

$table-editor-dialog-background-color: #eaebec;
$table-editor-header-background-color: #008658;
$table-editor-header-color: white;
$table-editor-border-color: #e2e2e2;
$table-editor-table-margin: 12px;

.rb-table-editor,
.rb-station-search,
.rb-station-diagnostic {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;

	.rb-dialog {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 0 $table-editor-table-margin 8px $table-editor-table-margin;
		background-color: $table-editor-dialog-background-color;

		.rb-dialog--header {
			background-color: $table-editor-header-background-color;
			color: $table-editor-header-color;
			flex: none;
			display: flex;
			justify-content: flex-start;
			width: unset;
			margin: 0 (-$table-editor-table-margin) $table-editor-table-margin
				(-$table-editor-table-margin);
			padding: 8px 10px;

			mat-icon {
				margin-right: 7px;
			}

			.mat-dialog-title {
				padding: unset;
				margin: unset;
				color: $table-editor-header-color;
			}
		}

		.rb-dialog--content {
			flex: 1;
			display: flex;
			max-height: unset;

			rb-editable-grid {
				flex: 1;
				display: flex;

				.rb-editable-grid-container {
					flex: 1;

					.ag-header {
						@include for-size('tablet', max) {
							display: flex;
						}
					}

					.ag-theme-material {
						.ag-pinned-left-header {
							border-right: 2px solid $table-editor-border-color;
						}

						.ag-ltr
							.ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned {
							border-right: 2px solid $table-editor-border-color;
						}
					}
				}
			}
		}

		.rb-dialog--footer {
			background-color: transparent;
			padding-top: 0;
		}
	}
}

// v27 Tree Data
$ag-row-group-indent-padding: 16px;
$ag-row-group-w-checkbox-indent-padding: 26px;

.flo-manager-tab,
.areas-tab-grid,
#golf-completed-tree {
	.ag-theme-material {
		.ag-ltr {

			.ag-group-contracted,
			.ag-group-expanded,
			.ag-selection-checkbox {
				margin-right: 4px;
			}

			@for $i from 1 through 15 {
				.ag-row-group-indent-#{$i} {
					padding-left: calc(#{$ag-row-group-indent-padding} * #{$i});
				}
			}

			.ag-row-group-leaf-indent {
				margin-left: 13px;
			}

			.ag-group-value-content {
				margin-left: 5px;
			}
		}
	}
}

.areas-tab-grid {
	.ag-theme-material {
		.ag-ltr {
			@for $i from 1 through 5 {
				.ag-row-group-indent-#{$i} {
					padding-left: calc(#{$ag-row-group-w-checkbox-indent-padding} * #{$i});
				}
			}
		}
	}

	.rb-base-mobile-cell-renderer {
		display: flex;
	}

	.mobile {
		.ag-cell-wrapper {
			flex: 1;

			.areas-mobile-cell {
				flex: 1;

				.mat-button-wrapper {
					display: flex;
					height: 100%;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.ag-row-level-1 {
			.ag-cell:nth-of-type(2) {
				.ag-cell-wrapper {
					margin-left: 10px;
				}
			}
		}
	}
}

.rb-move-flow-element {
	.ag-theme-material {
		.ag-ltr {
			.ag-row-group-leaf-indent {
				margin-left: 22px;
			}
		}
	}
}
