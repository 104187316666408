// ----START Custom ICon Menu Component----
.custom-element-menu {
	background-color: #fafafa;
	padding: 2pt;
	font-size: 10pt;
	z-index: 1000;
	box-shadow: 0 0 12pt rgba(0, 0, 0, 0.25);
	border-radius: 4pt;
	padding: 0.1em 0 0.5em 0;
	animation: fadeIn 0.1s ease-out;
	opacity:1.0;
	display:block;
  }
 .custom-element-menu hr {
	border: none;
	border-bottom: 1px solid #eee;
  }

  .custom-element-menu div {
	cursor: pointer;
	display: block;
	text-decoration: none;
	color: #333;
	padding: 0.1em 1em 0.5em 0.2em;
	max-width: 20em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
  }

  .custom-element-menu div:hover {
	background-color: #333;
	color: #fff;
  }


  .custom-element-menu div::before {
	content: '';
	float: left;
	margin-right: 0.5em;
	width: 0.5em;
	height: 1.2em;
	display: inline-block;
  }

  /* Animatinons */
  @-webkit-keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1.0;
	}
  }

  @keyframes fadeIn {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 1.0;
	}
  }

  @-webkit-keyframes fadeOut {
	from {
	  opacity: 1.0;
	}
	to {
	  opacity: 0.0;
	}
  }

  @keyframes fadeOut {
	from {
	  opacity: 1.0;
	}
	to {
	  opacity: 0.0;
	}
  }

  .is-fadingIn {
	-webkit-animation: fadeIn 0.1s ease-out;
	animation: fadeIn 0.1s ease-out;
	opacity: 1.0;
	display: block;
  }

  .is-fadingOut {
	-webkit-animation: fadeOut 0.1s ease-out;
	animation: fadeOut 0.1s ease-out;
	opacity: 0.0;
	display: block;
  }
  .icon-custom-element-menu {
	margin-left: -5px;
	margin-right: 12px;
	font-size: 15px;
	padding-top: 1px;
 }
 .icon-custom-element-menu-large {
	margin-left: -5px;
	margin-right: 10px;
	padding-top: -3px;
	font-size: 20px;
 }
 .icon-custom-element-menu-small {
	margin-left: -5px;
	margin-right: 12px;
	padding-top: 3px;
	font-size: 12px;
 }

 .icon-rotate-90 {
	transform: rotate(90deg);
}
 // ----END Custom ICon Menu Component----