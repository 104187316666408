.component-name {
	display: none;
	margin-top: 2px;
	margin-bottom: 2px;
	font-size: 10px;
	font-weight: 100;
	color: goldenrod;
}

// textWidth is used to set the width of numeric input fields for time input. 3rem is great for English, but
// for other languages it's inadequate. However 4rem seems to work for all the available languages.
.textWidth {
	width: 4rem;
}

.header {
	background-color: $primary;
	color: white;
}

.menu-button .mat-button.active .mat-button-focus-overlay {
	opacity: 0.1;
}

.main-nav-menu-item {
	.mat-button-ripple.mat-ripple:not(.mat-button-ripple-round) {
		.mat-ripple-element {
			background-color: black;
		}
	}
}

.header-expansion-panel.mat-expansion-panel {
	background: transparent;
}

.header-expansion-panel {
	.mat-expansion-panel-header-title {
		color: #ffffff;
	}
	.mat-menu-item {
		color: #ffffff;
		.material-icons {
			color: #ffffff;
		}
	}
	.mat-expansion-panel-header {
		.mat-expansion-indicator {
			&:after {
				color: #ffffff;
			}
		}
	}
}

.dashboard-main {
	height: calc(100vh - 2.5rem);
	overflow: scroll;
	width: 100%;
}

.color {
	color: $primary;
	outline-style: none;
}

.secondary-color {
	color: $secondary;
	outline-style: none;
}

.sidebar-name {
	overflow-wrap: break-word;
	width: 18rem;
}

.divOutline {
	width: 96.6vw;
}

.divider-vertical-left {
	border-left: 0.063rem solid #ededed;
}

.divider-vertical-right {
	border-right: 0.063rem solid #ededed;
}





.profile__placeholder {
	width: 9.375rem;
}



.box-shadow {
	box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.75);
}

.pageBreak {
	background-color: grey;
	height: 2rem;
}



.rb-admin-user {
	padding-left: 1.78rem;
}

.additional-filters .mat-form-field-infix {
	width: 4.375rem;
}

.controllerTextExt {
	@include truncate(11.563rem);
}

.allowed-on-text {
	position: initial;
	transform: scale(0.75) perspective(6.25rem) translateZ(0.001px);
}

.rb-duration {
	width: calc(100% + 2rem);
	margin-left: -1rem;
	margin-top: -1rem;
	margin-bottom: -1rem;
	overflow: hidden;

	.header {
		background-color: #008658;
		color: white;

		input {
			background-color: initial;
			border: initial;
			color: white;
		}
	}

	.mat-dialog-content {
		overflow: initial;
	}

	.mat-dialog-actions {
		margin-bottom: 0;
	}
}

.item-selection__container {
	background-color: #81B47B;
}



.rb-controller-tab {
	@include for-size('tablet') {
		display: block;
	}
}

.wrap-content {
	//word-break: break-word;
    //white-space: normal !important;
    //line-height: 24px !important;
}

.ellipsis-cell {
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.rb-icon-font-warning {
    color: #F1AC3B;
}
