@import "../settings/_RBCC-theme-variables";

$sync-icon-color-synced: #80b579;
$sync-icon-color-not-synced: #f1ac3b;
$sync-icon-color-incomplete: #b9121b;

.controller-grid-status-container {
	display: flex;
	align-items: center;

	.controller-sync-status-icon {
		&.controllers-grid {
			position: relative;
			top: unset;
			transform: none;
		}
	}

	.rb-status-icon {
		font-size: 19px;
	}

	.icon-error-style {
		color: #b9121b;
		margin-left: 5px;
		font-size: 22px;
	}

	.icon-auto-off {

	}

	.icon-rain-delay {
		color: dodgerblue;
	}

	.connection-status {
		color: $secondary;
		font-size: 14px;
		margin-left: 2px;
	}

	.connection-status-disconnected {
		color: $warn;
		font-size: 18px;
		margin-left: 2px;
	}

	.delta-state {
		font-size: 24px;
		margin-left: 2px;
		transform: rotate(180deg);
		color: dimgray;	// #262626;
	}
}

.controller-sync-status-icon {
	position: absolute;
	top:50%;
	transform: translateY(-50%);
	color: $sync-icon-color-synced;

	&.rb-spin,
	&.rb-spin-counterclockwise,
	&.rb-rotate-y {
		top:15%;
		transform: translateY(-15%);
	}

	&.not-synced {
		color: $sync-icon-color-not-synced;
	}

	&.incomplete {
		color: $sync-icon-color-incomplete;
	}

	&.sync-error {
		color: red;
	}

	&.rb-is-queued {
		color: gray;
	}
}

.icon-field-changes.not-synced {
	color: $sync-icon-color-not-synced;
	font-size: 19px;
	position: relative;
	top: -2px;
}

.rb-spin {
	animation: 1.5s linear rb-spin;
	animation-iteration-count: infinite;
}

.rb-spin-counterclockwise {
	animation: 1.5s linear rb-spin-counterclockwise;
	animation-iteration-count: infinite;
}

.rb-delayed-spin {
	animation: rb-delayed-spin 10s linear infinite;
	animation-direction: reverse;
}

.rb-rotate-x {
	animation: 1.5s linear rb-rotateX;
	animation-iteration-count: infinite;
}

.rb-rotate-y {
	animation: 1.5s linear rb-rotateY;
	animation-iteration-count: infinite;
}

@keyframes rb-spin {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}

@keyframes rb-spin-counterclockwise {
	from { transform: rotate(0deg); }
	to { transform: rotate(-359deg); }
}

@keyframes rb-rotateY {
	0% { transform: rotateY(0); }
	50% { transform: rotateY(180deg); }
	100% { transform: rotateY(359deg); }
}

@keyframes rb-rotateX {
	0% { transform: rotateX(0); }
	50% { transform: rotateX(180deg); }
	100% { transform: rotateX(359deg); }
}

@keyframes rb-delayed-spin {
	0% { transform: rotate(0deg);}		// Setting value to 0 for first 20% pauses the animation for 20% of total animation duration.
	20% { transform: rotate(0deg);}
	30% { transform: rotate(180deg);}	// Having the same value at 30% and again at 70% pauses the animation for 40% of total animation duration.
	70% { transform: rotate(180deg);}
	80% { transform: rotate(360deg);}
	100% { transform: rotate(360deg);}	// Having the same value at 80% and again at 100% pauses the animation for 20% of total animation duration.
}

.mobile-sync-icon-container {
	.controller-sync-status-icon {
		position: relative;
		top: 40%;

		&.rb-spin,
		&.rb-spin-counterclockwise,
		&.rb-rotate-y {
			top:-4%;
		}
	}
}
