// This file is reserved for Sass mixin declarations and functions.
// Mixins are the Sass equivalent to macros in other programming languages.
// Functions in Sass are used to return a value.
// https://sass-lang.com/documentation/file.SASS_REFERENCE.html



// Truncate Text
@mixin truncate($truncation-boundary) {
		max-width: $truncation-boundary;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		// --- Example of Usage ---
		//	div {
		//			 @include truncate(6.25rem);
		//	 }
}

// Media Queries - Responsive Breakpoint Mixin
$breakpoints: (
		"phone":            25em,	 //400px
		"phone-wide":       30em,	 //480px
		"phablet":          35em,	 //560px
		"tablet-small":     40em,	 //640px
		"tablet":           48em,	 //768px
		"tablet-wide":      51em,	 //816px
		"desktop":          64em,	 //1024px
		"desktop-wide":     75em,	 //1200px
		"desktop-large":    90em,	 //1440px
);

@mixin for-size($width, $type: min) {
		@if map_has_key($breakpoints, $width) {
				$width: map_get($breakpoints, $width);
				@if $type == max {
						$width: $width - 0.063em; //1px
				}
				@media only screen and (#{$type}-width: $width) {
						@content;
				}
		}
		// --- Example of Usage ---
		//			.site-header {
		//				padding: 2rem;
		//				font-size: 1.8rem;
		//				@include for-size('phone', max) {
		//						padding-top: 4rem;
		//						font-size: 2.4rem;
		//			 }
		//				@include for-size('desktop') {
		//						padding-top: 2rem;
		//						font-size: 1.4rem;
		//			 }
		//		}
}