
@font-face {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: normal;
    src: url('NotoSansJP-Regular.otf') format('opentype');
}

@font-face {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 700;
    src: url('NotoSansJP-Bold.otf') format('opentype');
}

@font-face {
    font-family: "Noto Sans JP";
    font-style: normal;
    font-weight: 300;
    src: url('NotoSansJP-Light.otf') format('opentype');
}
