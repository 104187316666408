// ---- START Timepicker ----

// Hide the dial hint text, which is hard to translate and not really necessary anyway. It normally would say
// something like "Use arrows to change the time".
.timepicker-dial__hint {
	visibility: hidden;
}

.rb-time-picker .timePicker-global .timepicker-backdrop-overlay,
.rb-time-picker .timePicker-global .timepicker-overlay,
.timepicker-backdrop-overlay,
.timepicker-overlay {
	z-index: 999999 !important;
	.timepicker {
		z-index: 999999 !important;
	}
}

.rb-time-picker .timepicker .timepicker__header,
.rb-start-time-toggle .timepicker .timepicker__header,
.rb-time-picker .timepicker .timepicker__main-content .clock-face__number > span.active,
.rb-start-time-toggle .timepicker .timepicker__main-content .clock-face__number > span.active,
.rb-time-picker .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand,
.rb-start-time-toggle .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand,
.rb-time-picker .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:after,
.rb-start-time-toggle .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:after,
.timepicker-overlay .timepicker .timepicker__header,
.timepicker-overlay .timepicker .timepicker__header,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face__number > span.active,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face__number > span.active,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:after,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:after {
	background-color: $primary;
}

@media (max-device-width: 500px) and (orientation: landscape) {
	.timepicker {
		width: 100% !important;

		.timepicker__header {
			padding-left: 5px !important;
			padding-right: 5px !important;
		}
	}
}

// // Make the time picker smaller on small screens to avoid UI issues - especially on iOS.
// .rb-time-picker .timePicker-global,
// ngx-material-timepicker .timepicker-overlay {
// 	@include for-size('tablet', max) {
// 		// .timepicker {
// 		// 	width: 15rem;
// 		// }

// 		.timepicker .timepicker__header {
// 			padding-top: 0.375rem;
// 			padding-bottom: 0.375rem;

// 			.timepicker-dial {
// 				.timepicker-dial__time {
// 					font-size: 2rem;
// 				}

// 				.timepicker-dial__control {
// 					font-size: 2rem;
// 					width: 2.5rem;
// 				}
// 			}
// 		}

// 		.timepicker .timepicker__body {
// 			padding-bottom: 0.625rem;
// 		}

// 		.timepicker .timepicker__actions {
// 			padding-top: 0.625rem;
// 		}

// 		.timepicker .clock-face {
// 			width: 12.5rem;
// 			height: 12.5rem;
// 			padding: 0.625rem;
// 		}

// 		.timepicker .clock-face__number--outer {
// 			height: calc(200px / 2 - 10px);
// 		}

// 		.timepicker .clock-face__clock-hand {
// 			height: 4.375rem;
// 			top: calc(50% - 70px);
// 		}
// 	}
// 	@include for-size('phone', max) {
// 		// .timepicker .clock-face {
// 		// 	width: 10rem;
// 		// 	height: 10rem;
// 		// 	padding: 0.5rem;
// 		// }

// 		// .timepicker .clock-face__number--outer {
// 		// 	height: calc(160px / 2 - 8px);
// 		// }

// 		// .timepicker .clock-face__clock-hand {
// 		// 	height: 3.5rem;
// 		// 	top: calc(50% - 56px);
// 		// }
// 	}
// }

.rb-time-picker .timepicker .timepicker__header .timepicker-dial,
.rb-start-time-toggle .timepicker .timepicker__header .timepicker-dial,
.timepicker-overlay .timepicker .timepicker__header .timepicker-dial,
.timepicker .timepicker__header .timepicker-dial {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.rb-time-picker .timepicker .timepicker__main-content .timepicker__actions .timepicker-button,
.rb-start-time-toggle .timepicker .timepicker__main-content .timepicker__actions .timepicker-button,
.timepicker-overlay .timepicker .timepicker__main-content .timepicker__actions .timepicker-button,
.timepicker .timepicker__main-content .timepicker__actions .timepicker-button {
	color: $primary;
}

.rb-time-picker .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:before,
.rb-start-time-toggle .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:before,
.timepicker-overlay .timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:before,
.timepicker .timepicker__main-content .clock-face .clock-face__clock-hand:before {
	border-color: $primary;
}

.rb-time-picker .timepicker .timepicker-dial .timepicker-dial__period .timepicker-dial__item_active,
.rb-start-time-toggle .timepicker .timepicker-dial .timepicker-dial__period .timepicker-dial__item_active,
.timepicker-overlay .timepicker .timepicker-dial .timepicker-dial__period .timepicker-dial__item_active,
.timepicker .timepicker-dial .timepicker-dial__period .timepicker-dial__item_active {
	color: #f1ac3b;
}

.rb-time-picker .color.mat-icon {
	color: #83b57c;
}
// ----END Timepicker----
