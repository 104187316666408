// ----START Quick Programming Dialog Component----
.quick-programming-dialog {
	.mat-checkbox-label {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
// ----END Quick Programming Dialog Component----
