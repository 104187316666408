
// =============================================================================================================================================================
// Program Group Schedule Tab, Program Steps Tab
// =============================================================================================================================================================

@media only screen and (max-width: 915px) {
	rb-program-group-schedule-tab,
	rb-program-steps-tab{
		.is-tablet {
			.rb-fab-button {
				top: -17px;
				left: 222px;

				button.mat-fab {
					height: 40px;
					width: 40px;

					span {
						padding: 0;
					}
				}
			}
		}
	}

	rb-controllers-tab {
		.is-tablet {
			.rb-fab-group-button {
				top: -17px;

				button.mat-fab {
					height: 40px;
					width: 40px;

					span {
						padding: 0;
					}
				}
			}
		}
	}
}
