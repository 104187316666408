// Prefix custom utils with rb to avoid conflict with tachyons or material classes
.rb-h4-green {
	color: $primary;
}

.rb-small-label {
	font-size: 0.69rem;
	color: $labelGrey;
}

.rb-flex-grow-0 {
	flex-grow: 0;
}

.rb-flex-grow-1 {
	flex-grow: 1;
}

.rb-flex-grow-2 {
	flex-grow: 2;
}

.rb-flex-grow-3 {
	flex-grow: 3;
}

.rb-min-width-1 {
	min-width: 20rem;
}

.rb-min-width-2 {
	min-width: 26rem;
}

.rb-mw10 {
	max-width: 120rem;
}

.rb-b-gray {
	background-color: $background;
}

.rb-bg-transparent-l {
	background-color: transparent;
}

.rb-gray-ns {
	background-color: #6a6a6a;
}

.rb-gray-m {
	background-color: #6a6a6a;
}

.rb-w-6-rem {
	width: 6rem;
}

.rb-w-10-rem {
	width: 10rem;
}

.rb-w-21-rem {
	width: 21rem;
}

.rb-w-35 {
	width: 35%;
}

.rb-vh-68 {
	height: 68vh;
}

.rb-word-wrap {
	word-wrap: break-word;
}

.rb-mla {
	margin-left: auto;
}

.rb-mra {
	margin-right: auto;
}