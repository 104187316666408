@import "../../styles/settings/RBCC-theme-variables";
@import "../../styles/tools/RBCC-modules";
@import "../../styles/settings/RBCC-calc-variables";

mat-dialog-container.mat-dialog-container {
	padding: 1rem;
}

.mat-display-1 {
	font-size: 1.75rem;
}

.mat-dialog-content {
	margin: 0;
	padding: 0;
}

.rb-dialog .mat-dialog-actions .mat-button + .mat-button {
	margin-left: 0;
}

.mat-button {
	padding: 0 0.666rem;
	min-width: 3.75rem;
}

//Material tab group styles
mat-tab-group {
	.mat-tab-list {
		.mat-tab-labels {
			background-color: $mediumGrey;
			padding: 0 4rem;

			.mat-tab-label {
				color: $white;
				opacity: 1;
				min-width: 0;
				padding: 0 1rem;
				margin-right: 2rem;
			}

			.mat-tab-label:focus {
				background-color: transparent;
			}
		}

		mat-ink-bar {
			height: 0.3rem;
		}
	}

	.mat-tab-body-wrapper {
		padding: 0 4rem;
		margin-top: 3rem;

		.mat-tab-body.mat-tab-body-active {
			width: 1px; // Allow for datatable to resize when the window changes
		}
	}
}

// Material form field styles
mat-form-field {
	width: 100%;

	.mat-form-field-wrapper {
		padding-bottom: 0.5rem;
	}

	.mat-form-field-underline {
		bottom: 0.5rem;
	}
}

mat-form-field[hidden] {
	display: none;
}

// Header styles
.header {
	.mat-form-field {
		label,
		span,
		.mat-select-arrow {
			color: $white;
		}

		.mat-form-field-underline,
		.mat-form-field-ripple {
			background-color: $white;
		}
	}

	.mat-form-field.mat-focused {
		label,
		span,
		.mat-select-arrow {
			color: $white;
		}

		.mat-form-field-underline,
		.mat-input-ripple {
			background-color: $white;
		}
	}
}

// Material icon styles
.mat-icon {
	vertical-align: middle;
}

// Material input styles
input.mat-input-element {
	height: 100%;
}

// Material menu should be allowed some width! The problem here is that .mat-menu-panel has a
// default max-width of about 280px which isn't enough for much. We add the other class,
// mat-menu-panel-max-width-none when we want to bypass that restriction. However, in order
// to use it for these material classes, the combination of the two classes has to be defined
// in the global styles file.
.mat-menu-panel.mat-menu-panel-max-width-none {
	max-width: none;
}

// Material navigation styles
.mat-tab-nav-bar {
	position: relative;
	border-bottom: none;

	.mat-tab-link {
		color: $navbarTextColor;
		opacity: 1;
		padding: 0 20px;
		&::after {
			display: block;
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0%;
			height: 0.125rem;
			transition: width .4s ease-out;
		}
		&.active {
			color: $white;
			&:hover {
				&::after {
					background-color: $level2NavbarUnderlineColor;
				}
			}
			&::after {
				background-color: $level2NavbarUnderlineColor;
				width: 100%;
			}
		}

		&:hover {
			color: $white;
			&::after {
				background-color: $navbarHoverUnderlineColor;
				width: 100%;
			}
		}
	}

	a.mat-tab-link {
		min-width: 0;
		height: 3.5rem;
	}
}

.multiselect-dropdown {
	overflow: initial !important;

	&.mat-select-panel {
		max-height: none;
	}

	.mat-select-content {
		box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
	}

	.mat-form-field-label {
		display: block;
		position: initial;
		margin-top: 1rem;
		padding: 1rem;
		transform: scale(0.75) perspective(100px);
	}

	.options-list {
		height: 25rem;
		overflow: auto;

		@media screen and (max-height: 50em) {
			height: 18rem;
		}

		@media screen and (max-height: 38em) {
			height: 15rem;
		}
	}

	&.select-holes-panel {

		padding: 10px;

		.hole-groups-select-many-area {
			display: flex;
			flex-wrap: wrap;
			margin-top: 4px;

			.hole-group-select-many {
				flex: 0 0 33%;

				.mat-checkbox {
					margin-top: 5px;
				}
			}
		}

		.hole-group {
			margin-top: 10px;

			.hole-group-title, .places-title {
				color: gray;
			}

			.hole-list {
				display: flex;
				flex-wrap: wrap;

				.mat-option {
					flex: 0 0 29%;
					margin-right: 8px;
					height: 2em;
					line-height: 2em;

					.mat-checkbox-label {
						max-width: 180px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				&.place {
					flex-direction: column;
					margin-right: 8px;
				}
			}
		}
	}

	.mat-pseudo-checkbox-checked::after {
		top: calc(50% - 4px);
		width: calc(100% - 4px);
		height: 4px;
	}
}


.mat-form-field.input-with-select {
	display: flex;
	align-items: center;
	overflow: hidden;

	.mat-form-field-wrapper {
		display: inherit;
		align-items: inherit;
		width: 100%;

		.mat-form-field-infix {
			display: inherit;
			align-items: inherit;

			input {
				flex-grow: 1;
				max-width: calc(100% - 20px);
			}

			.mat-select {
				width: 20px;
				height: 19px;

				.mat-select-value, .mat-select-placeholder {
					display: none;
				}
			}
		}
	}
}

// =============================================================================================================================================================
// Begin - Single Controller Selector
// =============================================================================================================================================================

@include for-size('tablet', max) {
	.root-container .report-filter {
		.single-controller-select.mat-select .mat-select-placeholder {
			color: black !important;
			-webkit-text-fill-color: black !important;
		}
	}
}

.single-controller-select.mat-select {

	.mat-select-placeholder {
		display: inline-block;
	}

}

.single-controller-select-panel {
	display: flex;
	flex-direction: column;

	&.mat-select-panel {
		max-height: 50vh;
	}

	.single-controller-select-header {
		min-height: 39px;
		box-shadow: lightgrey 2px 2px 5px;
	}

	.single-controller-select-radio-container {
		overflow-y: auto;

		.rb-radio-group {
			width: 100%;

			.mat-radio-label {
					width: 100%;

				.mat-radio-label-content {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}

// =============================================================================================================================================================
// Multi Controller Selector
// =============================================================================================================================================================

.multi-controller-select-panel {
	display: flex;
	flex-direction: column;

	&.mat-select-panel {
		max-height: calc(100vh - #{$main-header-height + $tp-secondary-navigation-header-height + $report-filter-vertical-offset});

	}

	&.medium-view.mat-select-panel {
		max-height: calc(80vh - #{$main-header-height + $tp-secondary-navigation-header-height + $report-filter-vertical-offset});
	}

	.multi-controller-select-header {
		min-height: 39px;
		box-shadow: lightgrey 2px 2px 5px;
	}

	.multi-controller-select-radio-container {
		overflow-y: auto;

		.mat-checkbox {
			//width: 100%;
			overflow: hidden;

			.mat-checkbox-layout {
				width: 100%;

				.mat-checkbox-label {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

		}
	}

}


// =============================================================================================================================================================
//
// =============================================================================================================================================================

.mat-drawer-container {
	background-color: #6a6a6a;
	/* Commenting this out to fix horizontal scrolling problem on mobile view. This may break something somewhere else, not sure yet.
    @include for-size('tablet', max) {
            overflow: visible!important;
        } */
}

.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
	background-color: #008658;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
	background-color: rgba(131, 181, 124, 0.55);
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
	background-color: #83b57c;
}

.mat-menu-panel .mat-menu-content {
	@include for-size('tablet') {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.mat-form-field-flex .mat-form-field-infix {
	@include for-size('tablet', max) {
		width: auto;
	}
}

.mat-drawer-content .mat-drawer-backdrop {
	@include for-size('tablet') {
		position: fixed;
	}
}

.rb-select-weekdays .mat-button-toggle-group {
	box-shadow: none;

	.mat-button-toggle-appearance-standard {
		.mat-button-toggle-label-content {
			line-height: 2.188rem;
			width: 2.188rem;
			padding: unset;
		}
	}

	.mat-select-all-options .mat-pseudo-checkbox {
		display: none;
	}

	.mat-button-toggle {
		border-radius: 6rem;
		box-shadow: none;
		border: none;
		color: black;
	}

	.mat-button-toggle-checked {
		box-shadow: none;
		font-weight: 800;
		background-color: $primary;
		color: $white;
	}
}

// ==========================================================================
// Materials Radio Buttons - Begin
// ==========================================================================

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $primary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
	color: $primary;
	background-color: $primary;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
	background-color: $primary;
}

// ==========================================================================
// Materials Radio Buttons - End
// ==========================================================================

.ag-theme-material .ag-icon-filter {
	font-family: "FontAwesome";
	font-weight: bold;

	&:before {
		font-size: 10pt;
		content: '\f0b0';
	}
}

.modern {
	.mat-tab-nav-bar {
	
		a.mat-tab-link {
			height: 56px;
		}
	}
}