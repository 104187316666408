$rb-btn-border-color: #555;

.rb-controller-manual-ops-container {
	.report-toolbar {
		.report-filter {
			&.alert-filter-container {

				@media (max-width: 1350px) {
					.alert-filter-ack-btn {
						.reportButton {
							font-size: 12px;
							padding: 0 7px;
							min-width: 0;
							max-width: 82px;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}

			@media (max-width: 1290px) {
				.report-filter-components .button-dropdown-daterange {
					.mat-form-field {
						padding: 0 1px;
					}
				}
			}

			@media (max-width: 1290px) {
				.reports-date-range-select {
					display: none;
				}
			}
		}

		@media (max-width: 1080px) {
			display: flex;
			align-items: center;

			#mobile-activity-filter-container {
				background-color: #666;
			}
		}
	}

	#manual-ops-diagnostics-toolbar-container {
		rb-icon-button {
			.icon-button-container {
				border-right: 1px solid $rb-btn-border-color;

				&.hide-border {
					border-right: none;
				}

				.mat-icon-button {
					height: 50px;
					width: unset;
					min-width: 50px;
				}

			}

			&.btn-short-report {
				.icon-button-container {
					padding: 0 10px;
				}
			}

		}
	}

}

// =============================================================================================================================================================
// Stations Tab
// =============================================================================================================================================================

rb-interface-stations-tab,
rb-stations-tab {
	.ag-cell span {
		&.fr-cell-units {
			font-size: 11px;
			color: #666;
			margin-left: 3px;
		}

		&.fr-cell-icon {
			margin-right: 5px;
			font-size: 18px;

			.learned {
				color: #80b579;
			}

			.manual {
				color: goldenrod;
			}
		}
	}
}

// =============================================================================================================================================================
// Manual Ops - Diagnostics Sub Tab
// =============================================================================================================================================================

$primary-font-color: #555;
$secondary-font-color: rgba(0, 0, 0, 0.54);
$header-background-color: #8A8B8D;
$secondary-header-color: #ddd;

rb-manual-ops-diagnostics-status {
	display: flex;
	overflow: auto;

	#mod-status-container {
		flex: 1;
		padding: 1rem;
		display: flex;
		flex-wrap: wrap;
		color: $primary-font-color;
		overflow: auto;

		.flex-child {
			display: flex;
		}

		.mod-status-pane {
			display: flex;
			flex-direction: column;
			margin-right: 1rem;

			.mods-section-heading {
				color: white;
				background-color: $header-background-color;
				font-size: 16px;
				padding: 6px 5px;
				white-space: nowrap;

				.mods-last-update {
					margin-left: 8px;
					font-size: 12px;
					color: $secondary-header-color;
				}
			}

			.mods-pane-content-wrapper {
				flex: 1;
				margin-bottom: 5px;
				min-height: 200px;
				padding: 1rem;
				position: relative;
				border: 1px solid #ededed;
				border-top: 1px solid #555;

				.mods-pane-scroll-container {
					position: relative;

					&.no-scroll {
						display: flex;

						.not-supported-or-configured {
							flex: 1;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #008658;
						}
					}

					.mods-dt-spacer {
						border: 1px solid lightgrey;
						margin: 10px 0;
					}

					.mods-data-table {
						th, td {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						th {
							text-align: left;
							padding: 4px 0;
						}

						td {
							padding-bottom: 5px;
						}

						.mods-dt-name-col {
							min-width: 200px;
							max-width: 300px;

							&.two-wire {
								min-width: 140px;
								max-width: 200px;
							}
						}

						.mods-dt-value_col {
							min-width: 60px;
							text-align: right;

							&.two-wire {
								padding-right: 10px;
							}

							&.ok-col {
								text-align: left;
							}
						}

						.mods-dt-mv-type-col {
							min-width: 140px;
						}

						.mods-dt-mv-status-col {
							min-width: 80px;
						}

					}
				}
			}

			.mods-pane-row {
				margin-bottom: 15px;
			}

			&#mod-status-left-pane {
				flex: 2.5;
				padding-right: 1rem;
				min-width: 700px;
				max-width: 800px;

				@media screen and (max-width: 1520px) {
					padding-right: 0;
				}

				@media screen and (max-width: 1200px) {
					min-width: 600px;
				}
			}

			&#mod-status-right-pane {
				flex: 1;
				min-width: 350px;
				max-width: 400px;
			}
		}

	}

	@media screen and (max-width: 1024px) {
		font-size: smaller;

		#mod-status-container .mod-status-pane {
			.mods-pane-content-wrapper {
				min-height: 175px;
			}
		}
	}
}

rb-manual-ops-diagnostics-short-report {
	rb-mod-lxd-short {
		.warning-icon {
			color: #F1AC3B;
		}
	}

}

// TODO: Used when Path and Segment info were displayed in same grid for LXIVM/p
// Hide Checkbox for LX-IVM Status/Short sub-tab
rb-mod-lx-ivm-short {
	//div[row-index="0"], div[row-index="1"] {
	//	pointer-events: none;
	//	touch-action: none;
	//
	//	.ag-cell.cellStyleCheckbox.hide-status-row-checkbox {
	//		display: none;
	//	}
	//}
}

// =============================================================================================================================================================
// Manual Ops - Irrigation Queue Tab
// =============================================================================================================================================================

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	&.child-dialog-backdrop {
		pointer-events: none;
		background: rgba(0, 0, 0, 0.32);
	}
}

.queue-commands-dlg-panel {
	max-width: 95% !important;

	.mat-dialog-container {
		.mat-dialog-content {
			div.ag-body-viewport {
				overflow-x: hidden;
			}
			.rb-table-wrapper-grid-container {
				height: 100%;
			}
		}
	}

	.mat-tab-group {
		.mat-tab-list {
			.mat-tab-labels {
				background-color: transparent;

				.mat-tab-label-content {
					color: rgba(0, 0, 0, 0.87);
				}
			}
		}
	}

	.child-dialog-backkdrop-guard {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 500;
	}
}

rb-irrigation-queue-commands {

	.rb-dialog {
		width: 600px;
		max-width: 100%;
	}

	.mat-tab-group {
		.mat-tab-list {
			.mat-tab-labels {
				padding: 0px 1rem;
			}
		}

		.mat-tab-body-wrapper {
			padding: 0;
			margin-top: 0.5rem;
		}
	}

	.queue-commands-container {
		.mat-tab-body {
			.qc-tab-content {
				height: calc(50vh);
				width: 100%;
				transition: all 0.5s;
				opacity: 0;

				&.fade-in {
					opacity: 1;
				}
			}
		}
	}
}

#controller-control-container {
	.mat-slide-toggle:not(.mat-checked) {
		.mat-slide-toggle-bar {
			background-color: rgba(185, 18, 27, 0.50);
		}

		.mat-slide-toggle-thumb {
			//background-color: rgba(185, 18, 27, 1);
			//background-color: #de4051; //lighter
			background-color: white;
			border: 1px solid rgba(185, 18, 27, 0.50);
		}
	}

	.mat-slide-toggle.mat-checked {
		.mat-slide-toggle-thumb {
			background-color: white;
			border: 1px solid #83b57c;
		}
	}
}

.alarms-badge-container {
	.mat-badge-content {
		background: #d0021b;
		font-size: 10px;
		right: -9px !important;
		width: 20px;
		height: 20px;
		line-height: 20px;
		top: -6px !important;
	}
}

.player-menu-mobile-container {
	.alarms-badge-container {
		.mat-badge-content {
			width: 18px;
			height: 18px;
			line-height: 18px;
			top: -2px !important;
		}
	}
}

.address-container {
	display: flex;
	align-items: flex-end;
	gap: 8px;
}
