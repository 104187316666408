@import "../../styles/settings/RBCC-theme-variables";

// ----START Top Navbar----
.rb-navbar {
	height: 4rem;
}

.rb-container {
	width: 100%;
}

.ag-theme-material .ag-layout-normal .ag-body {
	@include for-size('tablet', max) {
		position: initial;
	}
}

.top-nav {
	margin: 0 auto;
}

.rb-breadcrumb {
	align-items: center;
	display: flex;
	min-width: 23.125rem;
	overflow: hidden;
	padding-right: 16px;
	width: 20%;
}

.top-nav--secondary {
	display: flex;
	justify-content: center;
	height: 3.5rem;
	-webkit-box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.62);
	-moz-box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.62);
	box-shadow: 0 3px 6px 0 hsla(0, 0%, 0%, 0.62);

	&.top-nav-secondary-mobile {
		background-color: #777777;
		height: auto;
		margin-bottom: 0;
		box-shadow: none;

		.title-and-breadcrumb-container {
			background-color: $primary;
			display: flex;
			justify-content: space-between;
			// min-height: 54px;
			padding: 7px 0;
			width: 100%;

			.sub-nav-title-container {
				display: flex;
				align-items: center;
				min-height: 2.5rem;
				padding-left: 0.625rem;
			}

			.breadcrumb-arrow-back {
				padding-bottom: 0;
			}

			.rb-breadcrumb {
				width: auto;
				min-width: auto;
			}

			.mobile-header-buttons {
				display: flex;
				align-items: center;

			}
		}

		.sub-nav-content {
			flex-direction: column;
			overflow: hidden;
			padding: 0;
			background-color: $secondary;

			.sub-nav-tab {
				width: 100%;
				overflow-y: hidden;
				padding: 0 0.625rem;

				.mat-tab-nav-bar {
					overflow: auto;
				}
			}
		}
	}

	.sub-nav-content {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 120rem;
		padding: 0 2rem;
		overflow: auto;

		.sub-nav-title-container {
			flex-grow: 1;
			width: 20%;
			min-width: 23.125rem;

			.page-header-title {
				font-weight: 300;
				margin-top: 0;
			}
		}

		.sub-nav-tab {
			flex-grow: 3;
		}
	}
	.secondary-sub-nav {
		.mat-tab-links {
			gap: 16px;
		}
	}
}

//Removing for now. The nav color should only turn light green once you start drilling down more into the list.
/* .navbar-color__mobile {
	@include for-size('phone', max) {
		background-color: #80b579;
		color: white;
	}

	@include for-size('phone') {
		background-color: #80b579;
		color: white;
	}

	@include for-size('tablet') {
		background-color: transparent;
	}

} */

.logo-icon {
	//padding: 0;

	@include for-size('desktop', max) {
		margin-right: 0;
	}

	@include for-size('desktop') {
		margin-right: 0;
	}

	.mat-icon-button {
		width: 100%;
	}

	.mat-button {
		line-height: 1.15;
	}
}

.logo-icon-cirrus {
	padding: 0;
	line-height: 0;

	@include for-size('desktop', max) {
		margin-right: 0;
	}

	@include for-size('desktop') {
		margin-right: 0;
	}

	.mat-icon-button {
		width: 100%;
	}
}

.rb-navbar .main-nav-icons .mat-button,
.rb-navbar .main-nav-icons .mat-flat-button,
.rb-navbar .main-nav-icons .mat-icon-button,
.rb-navbar .main-nav-icons .mat-stroked-button {
	@include for-size('tablet') {
		margin: 0;
		padding: 0;
	}
}

.rb-container .sub-nav-tab .mat-tab-nav-bar .mat-tab-links .mat-tab-link {
	@include for-size('tablet') {
		padding: 0 0.563rem;
	}
	@include for-size('desktop') {
		padding: 0 1rem;
	}
	@include for-size('desktop-wide') {
		padding: 0 1.5rem;
	}
}
// ----END Top Navbar ----
