// ======================================================================================
// German
// ======================================================================================
html[user-language='de'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 14px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 70px;
    }

    .sub-edit-selection-area{
        .sub-edit-label.sfs{
            font-size: 11px !important;		
        }
        .sub-edit-value-and-arrow .sub-edit-value.sfs{
            font-size: 10.6px !important;
        }
    }
    
}


// ======================================================================================
// English
// ======================================================================================
html[user-language='en'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 19px;
                }
            }
        }
    }

    .rb-edit-panel {
        .edit-area-container {
            .fill-color-area {
                .fill-color {
                    width: 25% !important;
                }
            }

            .line-color-area {
                flex-direction: row !important;

                .lca-row {
                    width: 165px;
                    margin-right: 21px;

                    .line-color {
                        margin-right: 20px !important;
                    }

                    .line-opacity {
                            width: 69px !important;
                        };
                }
            }
        }
    }

}


// ======================================================================================
// Spanish
// ======================================================================================
html[user-language='es'] {

    rb-program-group-right-sidebar,
    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }

    rb-program-step-right-sidebar {
        rb-edit-program-step {
            rb-edit-panel {
                .radio-group-runtime {
                    font-size: 14px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 60px;
    }
}


// ======================================================================================
// French
// ======================================================================================
html[user-language='fr'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 18px;
                }
            }
        }
    }

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 16px;
                }
            }
        }
    }

    .rb-edit-panel {
        .edit-area-container {
            .fill-color-area, .line-color-area {
                flex-direction: column;

                .fill-color, .line-color {
                    width: 50% !important;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 80px;
    }

    .sub-edit-value-and-arrow .sub-edit-value.sfs{
        font-size: 10.6px !important;
    }
}


// ======================================================================================
// Italian
// ======================================================================================
html[user-language='it'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 16px;
                }
            }
        }
    }

    rb-program-step-right-sidebar {
        rb-edit-program-step {
            rb-edit-panel {
                .radio-group-runtime {
                    font-size: 14px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 70px;
    }

}


// ======================================================================================
// Japanese
// ======================================================================================
html[user-language='ja'] {

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 18px;
                }
            }
        }
    }

    rb-station-right-sidebar {
        rb-edit-station {
            rb-edit-panel {
                .sub-edit-label {
                    font-size: 15px !important;
                }
                .sub-edit-value {
                    font-size: 13px !important;
                }
            }
        }
    }

    rb-reorder-station {
        .location {
            width: 120px; 
        }
    }

    rb-program-step-right-sidebar {
        rb-edit-program-step {
            rb-edit-panel {
                .label {
                    width: 100px !important;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 70px;
    }
    
    .sub-edit-value-and-arrow .sub-edit-value.sfs{
            font-size: 10.8px !important;
    }
}


// ======================================================================================
// Korean
// ======================================================================================
html[user-language='ko'] {

    rb-program-step-right-sidebar {
        rb-edit-program-step {
            rb-edit-panel {
                .label {
                    width: 80px !important;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 35px;
    }
}


// ======================================================================================
// Portuguese
// ======================================================================================
html[user-language='pt'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 19px;
                }
            }
        }
    }

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 70px;
    }
}


// ======================================================================================
// Swedish
// ======================================================================================
html[user-language='sv'] {

    rb-program-group-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 18px;
                }
            }
        }
    }

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }
    
    .ag-theme-material .ag-header-select-all {
        margin-right: 30px;
    }
}


// ======================================================================================
// Vietnamese
// ======================================================================================
html[user-language='vi'] {

    rb-golf-sensor-right-sidebar {
        rb-sidebar-header {
            .rb-sidebar-header {
                .upper-case-title {
                    font-size: 17px;
                }
            }
        }
    }

    .ag-theme-material .ag-header-select-all {
        margin-right: 50px;
    }
}
