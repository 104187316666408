// ----START Side Panel Styling----
.right-panel .mat-slider-horizontal {
	@include for-size('tablet-wide') {
		width: 90%;
	}
}

.right-panel .mat-slider-horizontal {
	@include for-size('desktop') {
		width: 16rem;
	}
}

.right-panel .selfThreshold .mat-slider-horizontal {
	@include for-size('desktop') {
		width: 7.25rem;
	}
}

.left-panel .mat-form-field-appearance-legacy .mat-form-field-wrapper {
	padding-bottom: 1.45em;
}

.left-panel .selfThreshold .mat-slider-horizontal {
	@include for-size('tablet', max) {
		width: 90%;
	}
}

.left-panel .seefThreshold .mat-slider-horizontal {
	@include for-size('tablet', max) {
		width: 80%;
	}
}

.left-panel .divider {
	@include for-size('tablet', max) {
		display: none;
	}
}

.left-panel .selfThreshold .mat-slider-horizontal {
	@include for-size('desktop') {
		width: 8rem;
	}
}

.side-panel--edit .mat-dialog-content {
	overflow: initial;
}

.side-panel--edit.mat-drawer {
	overflow: initial;
}

.side-panel--edit .mat-dialog-actions {
	padding: 0;
	margin-bottom: 0;
}

.side-panel--edit,
.rb-hole-sub-tab__mat-drawer--edit,
.rb-area-sub-tab__mat-drawer--edit {
	@include for-size('tablet', max) {
		width: 100%;
		height: 85%;
	}
}
// ----END Side Panel Styling----