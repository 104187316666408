// ----START RB-DIALOG ----
.rb-dialog--content {
	.global-weather-dialog {
		padding: 2rem;
		width: 100%;
		position: absolute;
		overflow-y: scroll;
		top: 4rem;
		bottom: 4rem


	}
}

.rb-dialog--content {
	top: 4em;
	bottom: 3em;

	fieldset {
		padding: 0;
	}
}

.rb-dialog-embedded {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-x: hidden;
	overflow-y: auto;
	//height: 100%;

	.rb-dialog-content {
		display: flex;
		flex-grow: 1;
		overflow-y: auto;

		fieldset {
			width: 100%;
			padding-left: 1rem;
			padding-right: 1rem;
		}

		p {
			padding: 1rem;
			line-height: normal;
		}
	}

	.rb-dialog-footer {
		flex: none;
		//height: 55px;
		z-index: 20;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		box-shadow: 0 0 8px 0 rgba(0,0,0,.14);
		background-color: white;
		padding-right: 0;
		padding-top: 10px;
		padding-bottom: 10px;

		.rb-primary-button {
			margin-right: 1rem;
		}

		.rb-secondary-button {
			margin-right: 1rem;
		}

		&.hidden {
			display: none;
		}
	}
}

.rb-dialog--header {
	background-color: #80b579;
}

.side-panel--edit .rb-dialog .rb-dialog--header {
	background-color: #80b579;
	height: 4rem;
}

//.side-panel--edit .rb-dialog .rb-dialog--content {
//	padding: 1rem;
//	position: absolute;
//	top: 4em;
//    bottom: 3em;
//	overflow-y: scroll;
//}

.side-panel--edit .rb-dialog .rb-dialog--footer {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	bottom: 0;
	background-color: #ffffff;
	padding-bottom: 1rem;
	padding-top: 1rem;
	z-index: 9999;
}

.side-panel--edit .rb-dialog .rb-dialog--footer .rb-dialog--footer-bg {
	display: block;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
	z-index: -1;
	position: absolute;
	top: 0;
}

.side-panel--edit .rb-dialog .rb-dialog--header .mat-dialog-title {
	color: white;
	padding: 1rem;
}

.side-panel--edit .rb-dialog--header .mat-dialog-title {
	margin: 0;
}

.rb-detail-sidebar-content .rb-dialog--header .mat-dialog-title {
	margin-top: 0;
	margin-bottom: 0;
}

.rb-side-panel .rb-dialog--header .mat-dialog-title {
		margin: 0;
}

.mat-dialog-container .rb-dialog {
	position: relative;
}

.mat-dialog-container .rb-dialog--header {
	align-items: center;
	justify-content: center;
}

.rb-dialog {

	.rb-dialog--header {
		background-color: white;
		border-bottom: 0.063rem solid #ccc;

		.mat-dialog-title {
			padding: 0;
			margin: 0 0 10px 0;
			color: #000;
		}
	}

	.rb-dialog--content {
		position: initial;
		padding: 0;
		margin: 0;

		p {
			padding-top: 1rem;
		}
	}

	.rb-dialog--footer {
		position: initial;
		display: flex;
		flex-direction: column;

		.rb-dialog-footer-sep {
			box-shadow: grey 0 2px 3px;
			margin-left: -1rem;
			margin-right: -1rem;
			height: 1px;
		}

		.rb-dialog--footer-bg {
			display: none;
		}
	}

	table {
		td {
			padding: 0 0.5em;
		}
	}
}

.rb-dialog-password-validation {
	.warnColorText {
		margin-top: -0.9rem;
	}

	.validation-icon {
		font-size: 0.8rem;
		padding: 0;
		height: auto;
		width: auto;
	}
}
// ----END RB-DIALOG----
