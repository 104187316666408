@font-face
{
	font-family: "Lato";
	font-style: normal;
	font-weight: normal;
	src: url("Lato-Regular.ttf") format("truetype");
}
@font-face
{
	font-family: "Lato";
	font-style: italic;
	font-weight: normal;
	src: url("Lato-Italic.ttf") format("truetype");
}
@font-face
{
	font-family: "Lato";
	font-style: normal;
	font-weight: 700;
	src: url("Lato-Bold.ttf") format("truetype");
}
@font-face
{
	font-family: "Lato";
	font-style: italic;
	font-weight: 700;
	src: url("Lato-BoldItalic.ttf") format("truetype");
}
@font-face
{
	font-family: "Lato";
	font-style: normal;
	font-weight: 300;
	src: url("Lato-Light.ttf") format("truetype");
}
@font-face
{
	font-family: "Lato";
	font-style: italic;
	font-weight: 300;
	src: url("Lato-LightItalic.ttf") format("truetype");
}
