// ----START REPORTS----
.rb-report-container {
	table {
		border: none;
		padding: 0.5rem;
		width: 100%;

		th, td {
			padding: 0.5rem;
		}
	}

	.alternating-color-table tr:nth-child(even) {
		background-color: #f1f1f1;
	}

	.alternating-color-table tr:nth-child(even), .alternating-color-table thead {
		background-color: #ffffff;
	}

	.alternating-color-table tr:nth-child(odd) {
		background-color: #F7F3ED;
	}
}

rb-system-change-log-report {
	.report-filter-container.report-filter-components {
		.rb-date-range-dropdown {
			.reports-date-range-select {
				width: 155px !important;
			}
		}
	}
}

.report-toolbar {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	background-color: #666;

	&.no-padding {
		padding-left: 0;
		padding-right: 0;
	}

	.report-filter, .diagnostics-filter {

		@media (max-width: 768px) {
			display: flex;
			flex-grow: 1;
		}

		.report-filter-components {
			flex-grow: 1;
			display: flex;
			flex-wrap: wrap;

			.mat-form-field-appearance-legacy .mat-form-field-infix {
				margin: 0;
				padding: 0;
				border-top: none;
				font-size: 0.938rem;
				min-width: 90px;
				width: auto;
				display: flex;
				align-items: center;
			}

			.date-time-pair.is-mobile {
				.mat-form-field-appearance-legacy .mat-form-field-infix {
					flex: none;
				}
			}

			.mat-form-field-label-wrapper {
				padding-top: 0;
			}

			.button-dropdown,
			.button-dropdown-daterange {
				mat-form-field {
					white-space: nowrap;
					padding: 1rem .95rem 1.1rem;
					transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
					color: #fff;
					background-color: #666;
					border-right: solid 1px #555;

					@include for-size('tablet', max) {
						border-right: none;
					}
					@include for-size('desktop-wide', max) {
						padding-left: .3rem;
						padding-right: .3rem;
					}
				}

				&.has-time {

					mat-form-field {
						border-right: none;
					}
				}
			}

			.date-time-pair {
				display: inline-flex;

				@include for-size('tablet', max) {
					width: 100%;
				}
			}

			.reports-start-time, .reports-end-time {
				mat-form-field {
					white-space: nowrap;
					padding: 1rem 0 1.1rem 0;
					transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
					color: #fff;
					background-color: #666;
					border-right: solid 1px #555 !important;

					@include for-size('tablet', max) {
						border-right: none !important;
					}
				}

				@include for-size('tablet', max) {
					width: 100%;
				}
			}

			.button-dropdown {
				&.multi-controller-selector, &.single-controller-selector {
					min-width: 150px;
					max-width: 230px;

					@include for-size('tablet', max) {
						min-width: initial;
						max-width: initial;
					}
				}

				&.alarm-data-selector {
					min-width: 180px;
					max-width: 300px;

					&.mobile {
						width: 90%;
						max-width: initial;
					}

					@include for-size('tablet', max) {
						.mat-form-field-wrapper {
							width: 4rem;
						}
					}
					@include for-size('phone', max) {
						.mat-form-field-wrapper {
							width: 100%;
						}
					}
				}
			}

			.mat-form-field-label-wrapper,
			.mat-form-field-underline {
				display: none;
			}

			.mat-form-field-appearance-legacy .mat-form-field-wrapper {
				padding-bottom: 0;
			}

			.mat-form-field {
				line-height: 1.5;
			}

			.mat-raised-button {
				line-height: 2.125rem;
			}

			.rb-date-range-dropdown .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
				width: 1.3rem;
				margin-bottom: 0.063rem;
				color: white;
			}
		}

		.mobile-report-filter {

			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding-top: 10px;
			padding-bottom: 10px;
			width: 100%;

			.report-filter-components, .report-filter-button {
				display: flex;
				flex-direction: column;
				align-items: center;

				& > div {
					width: 90%;
					margin-top: 20px;
				}

				//.mat-form-field {
				//	padding-bottom: 4px;
				//	border-bottom: 1px solid #4d4d4d;
				//}

				//.mat-form-field-label-wrapper,
				//.mat-form-field-underline {
				//	display: inherit;
				//}
				//
				.button-dropdown,
				.button-dropdown-daterange mat-form-field {
					white-space: nowrap;
					padding: 0;
					color: #4d4d4d;
					background-color: white;

					&.alarm-data-selector {
						width: auto;

						&.mobile {
							width: 90% !important;
						}

						@include for-size('tablet', max) {
							.mat-form-field-wrapper {
								width: 100% !important;
							}
						}
						@include for-size('phone', max) {
							.mat-form-field-wrapper {
								width: 100%;
							}
						}
					}
				}

				.mat-form-field-appearance-legacy .mat-form-field-wrapper {
					padding-bottom: 0;
				}

				.mat-form-field {
					line-height: 1.35;
				}

				.rb-date-range-dropdown {
					width: 100%;

					.mat-form-field {
						margin-top: 2px;

						& ~ .mat-form-field {
							margin-top: 10px;
						}
					}

					.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
						color: #4d4d4d;
					}
				}

				.alarm-data, .rb-month-picker {
					width: 100%;

					.mat-form-field {
						margin-top: 2px;
					}

					.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
						color: #4d4d4d;
					}
				}
			}
		}
	}

	&.mobile {
		margin-top: 0;
		flex-grow: 1;
		background-color: white;
		display: flex;
		flex-direction: column;

		&.hidden {
			display: none;
		}
	}

	@include for-size('tablet', max) {
		overflow-x: auto;

		.report-filter-components {
			padding-left: 4px;

			.reports-start-date,
			.reports-end-date,
			.reports-date-range-select {
				margin-top: 2px !important;
				margin-bottom: 4px !important;
			}
		}

		.alert-filter-ack-btn {
			margin-left: 10px;
			padding-right: 4px;
		}
	}
}

.reports .mat-expansion-panel-body,
.user-preferences .mat-expansion-panel-body {
	padding: 0 0.313rem;
}

.report-table {
	@include for-size('desktop') {
		font-size: 0.625rem;
	}

	@include for-size('desktop-wide') {
		font-size: 1rem;
	}

	@include for-size('tablet', 'max') {
		font-size: .7rem;
	}

	//border: 1px solid lightgray;

	.report-table-header {
		//border-top: 2px solid lightgray;
		font-weight: bold;
		background-color: mat-color($RBCC-accent, 300);

		&:nth-child(even) {
			background-color: white;
		}

		&.super {
			background-color: mat-color($RBCC-accent, 600);
		}

		th {
			&.left {
				text-align: left;
			}
		}
	}

	th {
		border: 1px solid mat-color($RBCC-grey, lighter);

		&.empty {
			background-color: white;
			border: none;
		}
	}

	tr {
		&.empty {
			height: 0.3rem;
			background-color: white;
			border: none;

			&.second {
				display: none;
			}

			td {
				border: none;
			}
		}

		&.report-table-data:nth-child(odd) {
			background-color: mat-color($RBCC-accent, 200);
		}

		&.report-table-total-row {
			background-color: mat-color($RBCC-accent, 300);

			td {
				font-weight: bold;
			}
		}

		&.in-table-heading {
			td {
				border: none;
				background-color: white;
			}
		}
	}

	td {
		border: 1px solid mat-color($RBCC-grey, lighter);

		&.center {
			text-align: center;
		}

		&.right {
			text-align: right;
		}

		&.empty {
			background-color: white;
			border: none;
		}
	}
}

.report-table-note {
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: .9rem;
	color: mat-color($RBCC-grey);
}

.reports .gray {
	background-color: mat-color($RBCC-grey);
}

.word-break-all {
	word-break: break-all;
	@media (max-width: 768px) {
		p {
			font-size: 0.685rem;
		}
		tr {
			font-size: 0.660rem;
		}
	}
}

.wrap-content {
	word-break: break-word;
	white-space: normal;
}


.report-type-container {
	display: flex;
	align-items: center;

	img {
		height: 44px;
		min-width: 44px;
	}

	span {
		line-height: 44px;
		height: 44px;
		display: flex;
	}

	p {
		padding-left: 24px;
	}

}

.select-all-controls {

	.select-all.checklist-leaf-node {

		.mat-checkbox-layout  {
			padding-top: 10px;

			.mat-checkbox-inner-container {
				margin-left: 15px;
			}
			.mat-checkbox-label {
				font-weight: bold;
			}
		}
	}
}

// ----END REPORTS----
