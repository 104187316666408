/* ==========================================================================
   #RESET
   ========================================================================== */

/**
 * A very simple reset that sits on top of Normalize.css.
 */

body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
    margin: 0;
    padding: 0;
}


/**
 * Remove trailing margins from nested lists.
 */

li > {

    ol,
    ul {
        margin-bottom: 0;
    }

}


/**
 * Remove default table spacing.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}


/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */

fieldset {
    min-width: 0; /* [1] */
    border: 0;
}

// Inherited from old resets.scss file.
// Need to find a permenant home for these.
@import "~styles/settings/RBCC-theme-variables";

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;

    .japanese-font {
        font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic Pro', sans-serif;
    }
}

input {
    width: 100%;
}

.header {
    background-color: $mediumGrey;
}

.container {
    background-color: transparent;
}
