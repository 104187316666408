@import "../styles/settings/RBCC-theme-variables";
@import '../styles/settings/RBCC-calc-variables';

$not-responding-color: rgba(208, 2, 27, 0.75);
$responding-color: green;

$color-rb: #008751;
$color-blue: #116DAF;
$color-light-blue: #83D9F0;
$color-green: #089F6B;
$color-light-green: #66DBB3;
$color-yellow: #FFC924;
$color-red: #DA2036;
$color-orange: #FF961B;
$color-purple: #A30FEA;
$color-gray: #8A8A8A;
$color-tan: #A99D86;


//
// General Styles
//

.ellipsis-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 0.5rem;
}

html[browser-name='MS-Edge'] {
	rb-front-back-dropdown, rb-select-holes-dropdown {
		max-width: 200px;
	}

	.controller-radio-button {
		max-width: 180px;
	}
}

.d {
	&-none {
		display: none !important;
	}
	&-flex {
		display: flex !important;
	}
}

.text {
	&-blue {
		color: $color-blue !important;
	}
	&-light-blue {
		color: $color-light-blue !important;
	}
	&-green {
		color: $color-rb !important;
	}
	&-light-green {
		color: $color-green !important;
	}
	&-yellow {
		color: $color-yellow !important;
	}
	&-orange {
		color: $color-orange !important;
	}
	&-red {
		color: $color-red !important;
	}
	&-purple {
		color: $color-purple !important;
	}
	&-gray {
		color: $color-gray !important;
	}
}

.font {
	&-bold {
		font-weight: 700 !important;
	}
	&-italic {
		font-style: italic !important;
	}
}

.bg {
	&-blue {
		background-color: $color-blue !important;
	}
	&-light-blue {
		background-color: $color-light-blue !important;
	}
	&-green {
		background-color: $color-rb !important;
	}
	&-light-green {
		background-color: $color-light-green !important;
	}
	&-yellow {
		background-color: $color-yellow !important;
	}
	&-orange {
		background-color: $color-orange !important;
	}
	&-red {
		background-color: $color-red !important;
	}
	&-purple {
		background-color: $color-purple !important;
	}
	&-tan {
		background-color: $color-tan !important;
	}
}

.border {
	&-blue {
		border-color: $color-blue !important;
	}
	&-green {
		border-color: $color-rb !important;
	}
	&-yellow {
		border-color: $color-yellow !important;
	}
	&-orange {
		border-color: $color-orange !important;
	}
	&-red {
		border-color: $color-red !important;
	}
	&-purple {
		border-color: $color-purple !important;
	}
	&-tan {
		color: $color-tan !important;
	}
	&-gray {
		color: $color-gray !important;
	}
	&-white {
		color: white !important;
	}
	&-left {
		text-align: left !important;
	}
	&-center {
		text-align: center !important;
	}
	&-right {
		text-align: right !important;
	}
}


.rb-input-shield-host {
	position: relative;

	.rb-input-shield {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&--w-pointer {
			@extend .rb-input-shield;
			cursor: pointer;
		}
	}
}

//
//
//

.root-container {
	width: 100%;

	.rb-main-navbar {
		display: flex;
		justify-content: center;

		.logo-icon {
			line-height: 0;

			.logo-wrapper {
				line-height: 0;
			}

		}
	}

	.tri-pane-container {

		.left-pane {
			display: flex;
			flex-direction: column;
			width: $tp-left-pane-width;
			//margin-right: $tp-pane-separator-width;
			padding-right: $tp-pane-separator-width;
			padding-bottom: $tp-left-sidebar-bottom-padding;
			overflow-x: hidden;
			transition: width .25s, opacity .75s, visibility .2s;

			&.no-lsb-pad {
				padding-right: unset;
			}

			.left-pane-toolbar {
				height: $tp-toolbar-container-height;

				display: flex;
				justify-content: space-between;

				&.left-pane-toolbar-mobile {
					display: none;
				}

				.player-menu {
					overflow: auto;

					.player-menu-sub-container {
						padding: $tp-toolbar-container-padding;
					}
				}
			}

			.rb-sidebar-body {
				opacity: 1;
				transition: opacity 1s;
			}

			#lp-toggle-btn-container {
				display: flex;
				align-items: center;
				color: white;

				.mat-icon-button {
					border-radius: 0;
					//border: 1px solid #666;

					&:hover {
						background-color: darken($rb-dark-gray, $rb-percent-darker-for-hover);
					}
				}

				.player-menu__mat-button--icon {
					display: flex;
					justify-content: center;
					font-size: 34px;
				}
			}

			&.minimize-left-pane {
				// width: 30px;
				width: 16px;

				.left-pane-toolbar {
					justify-content: center;

					.player-menu {
						display: none;
					}
				}

				.rb-sidebar-body {
					transition: opacity 0.1s;
					opacity: 0;
				}

				.rb-sidebar-header {
					.mat-dialog-title {
						transition: opacity 0.1s;
						opacity: 0;
					}
				}

				.left-pane-toggle {
					border-radius: 2px;
					padding-left: 2px;
					padding-right: 2px;
				}

			}

			.left-pane-body {
				flex: 1;
				overflow: hidden;
				background-color: white;

				.rb-sb-read-only-controller {
					flex: 1;
					overflow: hidden;
				}
			}

			&.left-pane-mobile {
				width: 100%;
				margin-right: 0;
				padding-bottom: 0;

				.left-pane-toolbar {
					height: auto;
					justify-content: center;
				}
			}

		}

		.middle-pane {

			.play-menu {
				display: flex;
				justify-content: space-between;

				.rb-main-panel-toolbar {
					flex: 1;
					display: flex;
				}

				.player-menu {
					flex: 1;
					overflow: auto;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;

					.player-menu-sub-container {
						flex: 1;
						padding: 0;

						rb-front-back-dropdown, rb-select-holes-dropdown {
							padding-left: 15px;
							padding-right: 15px;
							border-right: solid 1px #555;
						}
					}
				}

				.report-filter-container {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
				}
			}

			// Mobile report filter
			.report-filter-container {
				overflow: auto;
				padding: 0.3rem;
			}

			.middle-pane-body {
				position: relative;

				.grid-placemat {
					background-color: white;
					position: absolute;
					top: 0;
					bottom: $tp-left-sidebar-bottom-padding;
					right: 0;
					left: 0;
				}

				.ag-grid-container {
					display: flex;
					justify-content: center;
					height: 100%;
					background-color: $background;
				}

				.rb-report-container {
					display: flex;
					position: relative;
					width: 100%;
					height: calc(100vh - #{$tp-vertical-grid-offset});
					background-color: white;

					@include for-size('tablet', max) {
						height: calc(100vh - #{$mobile-reports-tab-vertical-offset + $mobile-report-filter-height});
					}

					.report-body {
						width: 100%;
						flex-grow: 1;
						padding: 2rem;

						@include for-size('tablet', max) {
							padding: 1rem;
						}

						.report-table-container {
							@include for-size('tablet', max) {
								overflow: auto;
							}
						}
					}
				}

				.fadable-grid {
					opacity: 0;
					transition: opacity 0.5s;

					&.fade-in {
						opacity: 1;
					}
				}
			}

			@include for-size('tablet', max) {
				.report-filter-container {
					//position: fixed;
					//bottom: calc(0vh);
					width: 100%;
				}
			}

		}
	}

	.custom-report-tri-pane {
		.report-table-container {
			overflow: auto;
		}
	}

	.rb-tab-container {
		height: calc(100vh - #{$tp-vertical-drawer-offset});
		margin: 20px;
		padding: 20px;
	}

	.item-selection-container {
		color: white;
		background-color: $background;
		height: 3.375rem;
		padding-right: 0.625rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.item-selection {
			display: flex;
			justify-content: flex-end;
		}
	}

	.rb-sidebar-container {
		display: flex;
		flex-direction: column;
		overflow: hidden; // Prohibit scrolling of parent/host container.

		.rb-sidebar-header {
			flex: none;

			.rb-mobile-sidebar-header {

				.rb-sidebar-header {
					padding-left: 0;
					justify-content: space-between;

					.rb-sidebar-header-contents {
						display: flex;
						justify-content: space-between;
						width: 100%;

						.title-and-breadcrumb-container {
							overflow: hidden;

							.rb-breadcrumb {
								width: auto;
								min-width: auto;

								.breadcrumb-arrow-back {
									padding-bottom: 0;
								}

								.rb-breadcrumb--h1 {
									&.no-arrow {
										padding-left: 14px;
									}
								}
							}
						}
					}

					.rb-sidebar-header-buttons {
						button {
							margin-right: 0.25rem;
						}

						.fav-icon {
							cursor: pointer;
							padding: 0.3125rem 0;
							margin: 0 0.3125rem 0 0;
						}
					}
				}

			}
		}

		.rb-sidebar-body {
			overflow: hidden;
			display: flex;
			flex: 1;

			.sidebar-component {
				position: relative;
				height: 100%;
				overflow: hidden;

				.rb-dialog-embedded {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;

					.rb-dialog-content {

						.side-panel-body {
							.sub-edit-selection-areas {
								.sub-edit-selection-area {
									display: flex;
									justify-content: space-between;
									cursor: pointer;

									.sub-edit-label {
										display: flex;
										align-items: center;
									}

									.sub-edit-value-and-arrow {
										display: flex;
										align-items: center;
									}

									.sub-edit-value {
										color: #262626;
										font-size: 14px;

										&.sub-edit-info-label {
											color: #999;
										}
									}

									.sub-edit-arrow {
										display: flex;

										i {
											font-size: 32pt;
											margin-right: -10px;
										}
									}
								}
							}
						}

						.edit-golf-sensor-container {
							flex: 1;
							position: relative;
							overflow: hidden;

							.sub-edit-container {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 100%;
								display: flex;
								transition: left 0.5s, border-left-color 1s, border-left-width 1s;
								overflow: hidden;
								background-color: white;
								border-left: 2px solid lightgray;

								&.visible {
									left: 0;
									overflow-y: auto;
									border-left: 0 solid transparent;
								}

								.sub-edit-component {
									flex: 1;
									background-color: white;
									padding: 1rem;
								}
							}

						}
					}
				}
			}
		}

		&.reports-container {
			@include for-size('tablet', max) {
				height: unset;
				min-height: 41px;
			}
		}
	}

	.report-filter {
		.mat-select-placeholder {
			color: white !important;
			-webkit-text-fill-color: white !important;
		}
	}

	.mobile-report-filter {
		.mat-select-placeholder {
			color: #4d4d4d !important;
			-webkit-text-fill-color: #4d4d4d !important;
		}
	}

	.controller-tree {
		.mat-select-placeholder {
			color: #00000091;
			-webkit-text-fill-color: #00000091;
		}
	}


	@media screen and (max-width: 767px) {
		.rb-sub-navbar {
			display: none;
		}

		.item-selection-container {
			height: 2.3125rem;
		}
	}

}

.rb-dialog-container {
	min-width: 18.75rem;
	padding-top: 0.3125rem;
}

.rb-primary-button {
	&.mat-raised-button {
		border-radius: 2px;
		min-width: 5.5rem;
	}
}

.rb-secondary-button {
	&.mat-button {
		border-radius: 2px;
		min-width: 5.5rem;
	}
}

.mat-raised-button.mat-primary {
	border-radius: 4px;
	min-width: 5.5rem;

	.mat-button-wrapper {
		font-family: 'Lato';
		font-style: normal;
		font-weight: 400;
	}
}

.rb-disappear-effect {
	transition: all 1.5s;
	-webkit-transition: all 1.5s;
	opacity: 0;
}
// =============================================================================================================================================================
// Primary Framework Layout
// =============================================================================================================================================================

.main-app-container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.mat-drawer-container {
		flex: 1;
		display: flex;
		flex-direction: column;

		.mat-drawer-content {
			flex: 1;
			display: flex;
			flex-direction: column;

			.rb-breadcrumb-trail {
				height: 0;
			}

			.main-content-container {
				flex: 1;
				display: flex;
				flex-direction: column;
				overflow: hidden;

				.main-body {
					flex: 1;
					display: flex;
					flex-direction: column;
					min-height: 0;

					.container {
						flex: 1;
						display: flex;
						flex-direction: column;
						min-height: 0;

						.second-level-nav-host {
							flex: 1;
							display: flex;
							flex-direction: column;
							min-height: 0;
						}

						.second-level-nav {
							flex: 1;
							display: flex;
							flex-direction: column;
							min-height: 0;

							.rb-section-container {
								flex: 1;
								display: flex;
								flex-direction: column;
								min-height: 0;
								min-width: 0;

								.tri-pane-host {
									flex: 1;
									display: flex;
									flex-direction: column;

									.rb-tri-pane {
										flex: 1;
										display: flex;
										flex-direction: column;
										overflow: hidden;

										.tri-pane-container {
											flex: 1;
											display: flex;
											flex-direction: column;
											width: 100%;

											&.tri-pane-container-sans-drawer {
												.main-pane-container {
													flex: 1;
													overflow: hidden;
												}
											}

											&.mat-drawer-container {
												.right-pane {
													display: flex;
													flex-direction: column;

													.mat-drawer-inner-container {
														display: flex;
														flex: 1;

														.right-pane-body {
															display: flex;
															flex: 1;
															overflow: hidden;

															.right-sidebar-component {
																flex: 1;
																display: flex;
																flex-direction: column;
																overflow: hidden;

																.rb-sidebar-container {
																	flex: 1;
																}
															}
														}
													}
												}

												.left-pane-body {
													display: flex;

													.left-sidebar-component {
														display: flex;
														width: 100%;

														.rb-sidebar-container {
															width: 100%;

															&.firefox,
															&.ms-edge {
																position: relative;

																.rb-sidebar-body {
																	position: absolute;
																	top: 64px;
																	left: 0;
																	right: 0;
																	bottom: 0;
																}
															}
														}

													}
												}

												.mat-drawer-content {
													align-items: center;
												}
											}

										}

									}
								}

								.rb-custom-report-component {
									position: relative;
									flex: 1;
									display: flex;
									flex-direction: column;

									.rb-custom-reports-tri-pane {
										flex: 1;
										display: flex;
										position: absolute;
										top: 0;
										bottom: 0;
										left: 5px;
										right: 5px;
									}
								}

							}
						}
					}
				}

			}
		}

	}

}

.sidebar-component {
	flex: 1;
	display: flex;
	flex-direction: column;

	.rb-edit-panel {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

.absolute-child {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&.extended-bottom {
		bottom: -#{$tp-left-sidebar-bottom-padding}
	}
}

// =============================================================================================================================================================
// Controller Manual Ops Tab
// =============================================================================================================================================================

.rb-manual-ops-events-tab {
	flex: 1;
	display: flex;

	#manual-ops-events-container {
		display: flex;
		overflow: hidden;

		rb-events-tab {
			overflow: hidden;

			.play-menu {
				height: $rb-toolbar-height;

				.alert-filter-container {
					margin-top: 4px;
				}
			}
		}
	}

}

rb-manual-ops-irrigation-queue-tab,
rb-manual-ops-diagnostics-tab {
	#desktop-main-pane-toolbar {
		.player-menu {
			height: $rb-toolbar-height;
			padding-left: 2px;
		}
	}

	.right-pane-body {
		rb-controller-left-sidebar {
			flex: 1;
			display: flex;

			.rb-sidebar-container {
				flex: 1;
			}
		}
	}

	.manual_control_button.connected {
		.player-menu__mat-button--icon {
			background-color: white;
			border-radius: 15px;
			height: 16px;
			width: 16px;
			line-height: 17px;
			font-size: 16px;
			margin-top: 3px;
		}

		.player-menu__mat-button--icon-button-text {
			color: white;
			margin-top: 4px;
		}
	}
}

rb-manual-ops-diagnostics-ping-test,
rb-manual-ops-diagnostics-short-report,
rb-manual-ops-diagnostics-status,
rb-mod-lx-ivm-response {
	.response-status-unresponsive {
		color: $not-responding-color;
	}

	.response-status-responsive {
		color: $responding-color;
	}
}

// =============================================================================================================================================================
// Safari specific overrides
// =============================================================================================================================================================

html[browser-name='Safari'] {
	.top-nav--secondary {
		&.top-nav-secondary-mobile {
			.title-and-breadcrumb-container {
				min-height: 40px;
			}
		}
	}
}


.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
									 supported by Chrome, Opera and Firefox */
}

.arrow-btn-input {
	rb-number-arrow-button {
		visibility: hidden;
	}

	&:hover {
		rb-number-arrow-button {
			visibility: visible;
		}
	}
}



// =============================================================================================================================================================
// Display & Spacing
// =============================================================================================================================================================

.d-none {
	display: none !important;
}

.d-flex {
	display: flex !important;
}

.mt-0 { margin-top: 0 !important; }
.mr-0 { margin-right: 0 !important; }
.mb-0 { margin-bottom: 0 !important; }
.ml-0 { margin-left: 0 !important; }
.mt-1 { margin-top: .25rem !important; }
.mr-1 { margin-right: .25rem !important; }
.mb-1 { margin-bottom: .25rem !important; }
.ml-1 { margin-left: .25rem !important; }
.mt-2 { margin-top: .5rem !important; }
.mr-2 { margin-right: .5rem !important; }
.mb-2 { margin-bottom: .5rem !important; }
.ml-2 { margin-left: .5rem !important; }
.ml-auto { margin-left: auto !important; }
.mr-auto { margin-right: auto !important; }

.pt-0 { padding-top: 0rem !important; }
.pr-0 { padding-right: 0rem !important; }
.pb-0 { padding-bottom: 0rem !important; }
.pl-0 { padding-left: 0rem !important; }
.pt-1 { padding-top: .25rem !important; }
.pr-1 { padding-right: .25rem !important; }
.pb-1 { padding-bottom: .25rem !important; }
.pl-1 { padding-left: .25rem !important; }
.pt-2 { padding-top: .5rem !important; }
.pr-2 { padding-right: .5rem !important; }
.pb-2 { padding-bottom: .5rem !important; }
.pl-2 { padding-left: .5rem !important; }

// normalization

a.primary {
	color: $primary;
	cursor: pointer;
}