
.course-viewer_container {

	.mat-expansion-panel {
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
		box-shadow: none;

		&.mat-expanded {
			.mat-expansion-panel-header {
				background-color: rgba(74, 144, 226, 0.1);
				border-top: 1px solid lightgray;
				height: 48px !important;
			}
		}

		.mat-expansion-panel-header {
			.mat-expansion-indicator {
				display: flex;
			}
		}

		.mat-expansion-panel-content {
			.mat-expansion-panel-body {
				display: flex;
				flex-direction: row;
				padding-top: 15px;
			}
		}
	}

	.cv-right-toolbar {
		.mat-icon-button {
			width: 32px;
			height: 32px;
			line-height: 32px;

			.mat-icon {
				font-size: 18px;
			}
		}
	}

}

.cv-settings-menu {
	&.mat-menu-panel {
		overflow: hidden;
	}

	.mat-menu-separator {
		height: 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		hr {
			width: 85%;
			border: none;
			border-top: 1px solid lightgrey;
		}
	}

	.cv-data-view-rg-container {
		display: flex;
		flex-direction: column;
		padding: 0 16px;
		font-size: 14px;

		.cv-data-view-rg-title {
			margin-top: 2px;
			margin-bottom: 4px;
		}

		mat-radio-button:not(:last-child) {
			margin-bottom: 3px;
		}
	}
}

// Diagnostics Course Viewer tooltip - allow multi-line
.icm-status-tooltip {
	white-space: pre-line;
	font-size: 12px;
}
