button.rb-fab-speed-dial {
	margin-bottom: 0.625rem;
	border-radius: 0.313rem;
	width: 8.985rem;
	height: initial;
	color: #008658!important;
	background: white!important;
	text-align: center;
	border: 0.125rem solid #008658;
}

.rb-secondary-button {
	width: 7.500rem;
	margin-right: 2rem;
	color: $primary;
}

.outlined-button {
	background-color:	white; /* Green background */
	color: $primary; /* White text */
	border: 0.0625rem solid $primary;
	border-radius: 0.250rem;
	padding: 0.5rem 0.75rem;
	font-size: 0.875rem;
	margin: 0.138rem 0;

	&:disabled {
		border-color: $lightGrey;
		color: $lightGrey;
	}
}

button.inverted-button {
	background-color: $primary;
	color: white;
	border-radius: 0.250rem;
	padding: 0.5rem 0.75rem;
	font-size: 0.875rem;
	margin: 0.138rem 0;

	.mat-icon {
		margin-right: 5px;
		font-size: 1.25rem;
	}
}

button.bordered-icon {
	font-size: 14px;

	.mat-icon {
		border: 1px solid $primary;
		border-radius: 15px;
		font-size: 16px;
		margin-right: 5px;
		width: 18px;
		height: 18px;
	}

	&.no-text {
		padding-right: 0;

		.mat-icon {
			vertical-align: top;
		}
	}
}

.player-menu__mat-button--icon {
	font-size: 1.250rem;
}

.player-menu__mat-button--icon-button-text {
	font-size: 0.625rem;
	line-height: 0.625rem;
}

.player-menu .icon-button-container .mat-icon-button {
	border-radius: 0;
}

.rb-select-weekdays .mat-button-toggle-label .mat-button-toggle-label-content {
	width: 2.188rem;
	height: 2.188rem;
	padding: 0;
	margin: 0;
	text-align: center;
	line-height: 2.188rem;
}

.closeButton {
	display: none;
}

.mat-dialog-container .closeButton {
	display: flex;
	cursor: pointer;
}

button.danger-button {
	height: 36px;
	min-width: fit-content;
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 24px;
	background: $danger;
	color: white;

	&:hover {
		background: $dangerHover;
	}

	&:disabled {
		background: $dangerDisabled;
		opacity: 50%;
	}

	&:active:enabled {
		background: $dangerClicked;
	}
}
