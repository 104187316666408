@import "../tools/RBCC-modules";
@import "../flat-ui";

// ----START MAP----
.map-layers-menu-container+* .mat-menu-panel {
	background: $rb-gray-background-dark-accent-color;
	max-width: inherit;
	overflow: hidden;
}

.map-layers-menu {
	width: 250px;
	background-color: $rb-gray-background-dark-accent-color;
	display: flex;
	flex-direction: column;
	color: white;

	.map-layer-option {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 100;
		border-top: solid 1px #aaaaaa33;
		padding: 5px 20px;
		padding-left: 40px;
		align-items: center;

		.expand-button {
			cursor: pointer;
			font-size: 20px;
			left: 12px;
			position: absolute;
			transition: transform 0.3s ease;
			&.open {
				transform: rotate(180deg);
			}
		}

		&:first-child {
			border-top: none;
		}

		.mat-slide-toggle.mat-disabled {
			.mat-slide-toggle-bar { background-color: #88888877; }
			.mat-slide-toggle-thumb { background-color: #333; }
		}

		.mat-slide-toggle:not(.mat-disabled) {
			&.map-option-slider-1 .mat-slide-toggle-bar { background-color: #f3b54377; }
			&.map-option-slider-2 .mat-slide-toggle-bar { background-color: #588f2777; }
			&.map-option-slider-3 .mat-slide-toggle-bar { background-color: #2a2a2a77; }
			&.map-option-slider-4 .mat-slide-toggle-bar { background-color: #1976d277; }
			&.map-option-slider-5 .mat-slide-toggle-bar { background-color: #b9121b77; }
			&.map-option-slider-6 .mat-slide-toggle-bar { background-color: #aaa; }
			&.map-option-slider-8 .mat-slide-toggle-bar { background-color: #aaa; }
			&.map-option-slider-22 .mat-slide-toggle-bar { background-color: #2a2a2a77; }
			&.map-option-slider-24 .mat-slide-toggle-bar { background-color: #008B7C77; }
		}

		.mat-slide-toggle:not(.mat-disabled) {
			&.map-option-slider-1 .mat-slide-toggle-thumb { background-color: #f3b543; }
			&.map-option-slider-2 .mat-slide-toggle-thumb { background-color: #588f27; }
			&.map-option-slider-3 .mat-slide-toggle-thumb { background-color: #2a2a2a; }
			&.map-option-slider-4 .mat-slide-toggle-thumb { background-color: #1976d2; }
			&.map-option-slider-5 .mat-slide-toggle-thumb { background-color: #b9121b; }
			&.map-option-slider-6 .mat-slide-toggle-thumb { background-color: #888; }
			&.map-option-slider-8 .mat-slide-toggle-thumb { background-color: #888; }
			&.map-option-slider-22 .mat-slide-toggle-thumb { background-color: #2a2a2a; }
			&.map-option-slider-26 .mat-slide-toggle-thumb { background-color: #008B7C; }
		}

		&.sub-option {

			.mat-slide-toggle:not(.mat-disabled) {
				&.map-option-slider-10 .mat-slide-toggle-bar,
				&.map-option-slider-11 .mat-slide-toggle-bar,
				&.map-option-slider-12 .mat-slide-toggle-bar,
				&.map-option-slider-13 .mat-slide-toggle-bar,
				&.map-option-slider-14 .mat-slide-toggle-bar,
				&.map-option-slider-19 .mat-slide-toggle-bar,
				&.map-option-slider-21 .mat-slide-toggle-bar,
				&.map-option-slider-23 .mat-slide-toggle-bar,
				&.map-option-slider-25 .mat-slide-toggle-bar,
				&.map-option-slider-14 .mat-slide-toggle-bar { background-color: #ffffff77; }
			}
	
			.mat-slide-toggle:not(.mat-disabled) {
				&.map-option-slider-10 .mat-slide-toggle-thumb,
				&.map-option-slider-11 .mat-slide-toggle-thumb,
				&.map-option-slider-13 .mat-slide-toggle-thumb,
				&.map-option-slider-14 .mat-slide-toggle-thumb,
				&.map-option-slider-19 .mat-slide-toggle-thumb { background-color: #2a2a2a; }
				&.map-option-slider-21 .mat-slide-toggle-thumb,
				&.map-option-slider-23 .mat-slide-toggle-thumb,
				&.map-option-slider-25 .mat-slide-toggle-thumb,
				&.map-option-slider-14 .mat-slide-toggle-thumb { background-color: #2a2a2a; }
				&.map-option-slider-12 .mat-slide-toggle-thumb { background-color: #1976d2; }
				&.map-option-slider-23 .mat-slide-toggle-thumb { background-color: #008B7C; }
			}
		}
	}
}

.map-alert-popup-container {
	cursor: auto;
	height: 0;
	position: absolute;
	width: 300px;

	.map-alert-popup-anchor {
		/* Position the div a fixed distance above the tip. */
		position: absolute;
		width: 100%;
		bottom: 8px;
		left: 0;

		.map-alert-popup-content {
			/* Position the bubble centered-above its parent. */
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(-50%, -100%);
			/* Style the bubble. */
			background-color: #5d5d5d;
			color: white;
			font-size: 13px;
			padding: 5px 10px 10px 10px;
			//border-radius: 5px;
			font-family: sans-serif;
			overflow-y: auto;
			//max-height: 60px;
			box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.5);

			.map-alert-popup-content-info {
				margin-top: 5px;
			}
			.map-alert-popup-content-info:before {
				content:"• ";
			}
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			/* Center the tip horizontally. */
			transform: translate(-50%, 0);
			/* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
			width: 0;
			height: 0;
			/* The tip is 8px high, and 12px wide. */
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: /* TIP_HEIGHT= */ 8px solid #5d5d5d;
		}
	}
}
// ----END MAP----
