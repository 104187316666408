@import "~styles/settings/RBCC-themes";
@import "~styles/settings/RBCC-theme-variables";

$gwpw-medium-gray: #454545;
$gwpw-dark-gray: #262626;
$gwpw-light-gray: #b3b3b3;

.whiteCard {
	background-color: $white;
}
.markup {
	background-color: #f5f2f0;
}

.primaryColor {
	background-color: mat-color($RBCC-primary);
	color: mat-color($RBCC-primary, default-contrast);
}
.secondaryColor {
	background-color: mat-color($RBCC-secondary);
}
.accentColor {
	background-color: mat-color($RBCC-accent);
	color: mat-color($RBCC-accent, default-contrast);
}
.warnColor {
	background-color: mat-color($RBCC-warn);
	color: mat-color($RBCC-warn, default-contrast);
}
.warnColorText {
	color: red;
	background-color: $white;
	padding-bottom: 1rem;
	font-size: 0.75rem;
	margin-top: -0.985rem;
}
.lightGreyColor {
	background-color: mat-color($RBCC-background, lighter);
	color: mat-color($RBCC-background, lighter-contrast);
}
.mediumGreyColor {
	background-color: mat-color($RBCC-background);
	color: mat-color($RBCC-background, default-contrast);
}
.darkGreyColor {
	background-color: mat-color($RBCC-background, darker);
	color: mat-color($RBCC-background, darker-contrast);
}
.grayBackgroundColor {
	background-color: $background;
}
.helperErrorColorText {
	color: #DA2036;
}
.helperSuccessColorText {
	color: #089F6B ;
}