// ----START Dashboard Component----
.create-station-dialog--places {
	margin-top: 1.250rem;
}

.dialog-body {
	width: calc(100% + 3rem); 
	margin-left: -2rem;
}

.dialog-footer {
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.14);
	z-index: -1;
	top: 0;
	width: calc(100% + 4rem); 
	margin-left: -2rem;
}
// ----END Dashboard Component----