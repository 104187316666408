// ----START Add Station Component----
.flow-alarms-dialog {
	@include for-size('phone', max) {
		width: 90%;
		max-width: 90vw !important;
	}

	.mat-dialog-container {
		.mat-dialog-content {

			div.ag-body-viewport {
				overflow-x: hidden;
			}
			.rb-table-wrapper-grid-container {
				height: calc(50vh);
				max-height: 321px;
			}
		}

		.rb-dialog {
			@include for-size('phone', max) {
				min-width: 14rem;
			}

			@include for-size('phone') {
				min-width: 17rem;
			}

			@include for-size('tablet') {
				min-width: 30rem;
			}

			@include for-size('desktop') {
				min-width: 32rem;
				width: 38rem;
			}
		}
	}
}
