
// =============================================================================================================================================================
// Common Layout Variables
// =============================================================================================================================================================

$main-header-height: 4rem;
$mobile-main-header-height: 4rem;
$mobile-main-footer-height: 2.5rem;
$mobile-sub-nav-headers-sans-title: 2.5rem;
$rb-toolbar-height: 66px;

// =============================================================================================================================================================
// Tri-Pane Layout Variables
// =============================================================================================================================================================

$tp-secondary-navigation-header-height: 3.5rem;
$tp-sub-nav-shadow-height: 0.4375rem;
$tp-bottom-padding: 3.9375rem;						// Adjust increase/decrease height of grid and bottom margin (inversely).
$tp-left-sidebar-bottom-padding: 3.4375rem;

$tp-toolbar-container-height: 3.625rem;	//4.0625rem;
$tp-toolbar-container-padding: 0 0 0.5625rem 0;

$tp-left-pane-width: 22.5rem;
$tp-pane-separator-width: 1rem;

$tri-pane-vertical-offset: $main-header-height + $tp-secondary-navigation-header-height + $tp-sub-nav-shadow-height;
//$mobile-tri-pane-vertical-offset: $mobile-main-header-height + $mobile-main-footer-height;
$mobile-tri-pane-vertical-offset: $mobile-main-header-height;

$tp-vertical-grid-offset: $main-header-height + $tp-secondary-navigation-header-height + $tp-toolbar-container-height + $tp-bottom-padding;
$tp-vertical-drawer-offset: $main-header-height + $tp-secondary-navigation-header-height + $tp-bottom-padding;

// =============================================================================================================================================================
// Reports Tab Variables
// =============================================================================================================================================================

$mobile-report-filter-height: 2.899rem;
$mobile-reports-tab-vertical-offset: $mobile-main-header-height + $mobile-sub-nav-headers-sans-title;

$report-filter-vertical-offset: 6.5rem;
