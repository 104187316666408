@font-face {
  font-family: 'rb-icon-font';
  src: url('../font/rb-icon-font.eot?44236565');
  src: url('../font/rb-icon-font.eot?44236565#iefix') format('embedded-opentype'),
       url('../font/rb-icon-font.woff2?44236565') format('woff2'),
       url('../font/rb-icon-font.woff?44236565') format('woff'),
       url('../font/rb-icon-font.ttf?44236565') format('truetype'),
       url('../font/rb-icon-font.svg?44236565#rb-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'rb-icon-font';
    src: url('../font/rb-icon-font.svg?44236565#rb-icon-font') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "rb-icon-font";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-activity:before { content: '\e800'; } /* '' */
.icon-add:before { content: '\e801'; } /* '' */
.icon-advance:before { content: '\e802'; } /* '' */
.icon-alert_v2:before { content: '\e803'; } /* '' */
.icon-alert:before { content: '\e804'; } /* '' */
.icon-area:before { content: '\e805'; } /* '' */
.icon-arrow-left:before { content: '\e806'; } /* '' */
.icon-arrow-right:before { content: '\e807'; } /* '' */
.icon-arrow-up:before { content: '\e808'; } /* '' */
.icon-arrow:before { content: '\e809'; } /* '' */
.icon-auto-off:before { content: '\e80a'; } /* '' */
.icon-calendar:before { content: '\e80b'; } /* '' */
.icon-sync_problem:before { content: '\e80c'; } /* '' */
.icon-chart:before { content: '\e80d'; } /* '' */
.icon-check-box-outline:before { content: '\e80e'; } /* '' */
.icon-check-box:before { content: '\e80f'; } /* '' */
.icon-calculate:before { content: '\e810'; } /* '' */
.icon-clone:before { content: '\e811'; } /* '' */
.icon-cloud:before { content: '\e812'; } /* '' */
.icon-compass:before { content: '\e813'; } /* '' */
.icon-confirmation:before { content: '\e814'; } /* '' */
.icon-connection:before { content: '\e815'; } /* '' */
.icon-controller:before { content: '\e816'; } /* '' */
.icon-dashboard:before { content: '\e817'; } /* '' */
.icon-delete:before { content: '\e818'; } /* '' */
.icon-station_disconnected:before { content: '\e819'; } /* '' */
.icon-disconnect:before { content: '\e81a'; } /* '' */
.icon-document:before { content: '\e81b'; } /* '' */
.icon-drag-indicator:before { content: '\e81c'; } /* '' */
.icon-drag:before { content: '\e81d'; } /* '' */
.icon-drawing-lasso:before { content: '\e81e'; } /* '' */
.icon-drawing:before { content: '\e81f'; } /* '' */
.icon-edit:before { content: '\e820'; } /* '' */
.icon-field-changes:before { content: '\e821'; } /* '' */
.icon-filter:before { content: '\e822'; } /* '' */
.icon-filter2:before { content: '\e823'; } /* '' */
.icon-firmware:before { content: '\e824'; } /* '' */
.icon-flag:before { content: '\e825'; } /* '' */
.icon-flow:before { content: '\e826'; } /* '' */
.icon-hamburger-menu:before { content: '\e827'; } /* '' */
.icon-help:before { content: '\e828'; } /* '' */
.icon-import-export:before { content: '\e829'; } /* '' */
.icon-inch-value:before { content: '\e82a'; } /* '' */
.icon-indeterminated-check-box:before { content: '\e82b'; } /* '' */
.icon-info:before { content: '\e82c'; } /* '' */
.icon-insert:before { content: '\e82d'; } /* '' */
.icon-layers:before { content: '\e82e'; } /* '' */
.icon-link:before { content: '\e82f'; } /* '' */
.icon-list:before { content: '\e830'; } /* '' */
.icon-location:before { content: '\e831'; } /* '' */
.icon-log:before { content: '\e832'; } /* '' */
.icon-map-pin:before { content: '\e833'; } /* '' */
.icon-station_suspended:before { content: '\e834'; } /* '' */
.icon-more:before { content: '\e835'; } /* '' */
.icon-move:before { content: '\e836'; } /* '' */
.icon-weather-station:before { content: '\e837'; } /* '' */
.icon-notification:before { content: '\e838'; } /* '' */
.icon-pause:before { content: '\e839'; } /* '' */
.icon-pen:before { content: '\e83a'; } /* '' */
.icon-person-location:before { content: '\e83b'; } /* '' */
.icon-pin-code:before { content: '\e83c'; } /* '' */
.icon-pipe:before { content: '\e83d'; } /* '' */
.icon-spin:before { content: '\e83e'; } /* '' */
.icon-power:before { content: '\e83f'; } /* '' */
.icon-print:before { content: '\e840'; } /* '' */
.icon-profile:before { content: '\e841'; } /* '' */
.icon-program:before { content: '\e842'; } /* '' */
.icon-pump:before { content: '\e843'; } /* '' */
.icon-question:before { content: '\e844'; } /* '' */
.icon-radio-button-checked:before { content: '\e845'; } /* '' */
.icon-radio-button:before { content: '\e846'; } /* '' */
.icon-rain-delay:before { content: '\e847'; } /* '' */
.icon-refresh:before { content: '\e848'; } /* '' */
.icon-remove:before { content: '\e849'; } /* '' */
.icon-report:before { content: '\e84a'; } /* '' */
.icon-reverse:before { content: '\e84b'; } /* '' */
.icon-satellite:before { content: '\e84c'; } /* '' */
.icon-search:before { content: '\e84d'; } /* '' */
.icon-play-with-circle:before { content: '\e84e'; } /* '' */
.icon-settings-1:before { content: '\e84f'; } /* '' */
.icon-snyc:before { content: '\e850'; } /* '' */
.icon-sprinkler:before { content: '\e851'; } /* '' */
.icon-start:before { content: '\e852'; } /* '' */
.icon-stations:before { content: '\e853'; } /* '' */
.icon-stop:before { content: '\e854'; } /* '' */
.icon-sun:before { content: '\e855'; } /* '' */
.icon-time:before { content: '\e856'; } /* '' */
.icon-timer:before { content: '\e857'; } /* '' */
.icon-toogle-off:before { content: '\e858'; } /* '' */
.icon-toogle-on:before { content: '\e859'; } /* '' */
.icon-tool:before { content: '\e85a'; } /* '' */
.icon-tree:before { content: '\e85b'; } /* '' */
.icon-undo:before { content: '\e85c'; } /* '' */
.icon-warning:before { content: '\e85d'; } /* '' */
.icon-water:before { content: '\e85e'; } /* '' */
.icon-wire:before { content: '\e85f'; } /* '' */
.icon-x:before { content: '\e860'; } /* '' */
.icon-zoom-map:before { content: '\e861'; } /* '' */
.icon-cancel:before { content: '\e862'; } /* '' */
.icon-check:before { content: '\e863'; } /* '' */
.icon-direction-arrow:before { content: '\e864'; } /* '' */
.icon-minus:before { content: '\e865'; } /* '' */
.icon-plus:before { content: '\e866'; } /* '' */
.icon-move2top:before { content: '\e867'; } /* '' */
.icon-selector:before { content: '\e868'; } /* '' */
.icon-play-circled:before { content: '\e86a'; } /* '' */
.icon-controller-output:before { content: '\e86c'; } /* '' */
.icon-decoder-test:before { content: '\e86d'; } /* '' */
.icon-list-not-responding:before { content: '\e86e'; } /* '' */
.icon-list-responding:before { content: '\e86f'; } /* '' */
.icon-ping-decoders:before { content: '\e870'; } /* '' */
.icon-ping-valves:before { content: '\e871'; } /* '' */
.icon-raster-test:before { content: '\e872'; } /* '' */
.icon-short-finding:before { content: '\e873'; } /* '' */
.icon-status:before { content: '\e874'; } /* '' */
.icon-test-all:before { content: '\e875'; } /* '' */
.icon-test-shorted-paths:before { content: '\e876'; } /* '' */
.icon-station_connected:before { content: '\e877'; } /* '' */
.icon-voltage_green:before { content: '\e878'; } /* '' */
.icon-voltage_red:before { content: '\e879'; } /* '' */
.icon-sm-drag-drop:before { content: '\e87a'; } /* '' */
.icon-icon-info:before { content: '\e87b'; } /* '' */
.icon-square:before { content: '\e87c'; } /* '' */
.icon-filter-remove:before { content: '\e87d'; } /* '' */
.icon-filter-3:before { content: '\e87e'; } /* '' */
.icon-drag-indicator-outlined:before { content: '\e87f'; } /* '' */
.icon-edit-classic:before { content: '\e880'; } /* '' */
.icon-alarm-active:before { content: '\e881'; } /* '' */
.icon-delete-with-cross:before { content: '\e882'; } /* '' */
.icon-open-eye:before { content: '\e883'; } /* '' */
.icon-close-eye:before { content: '\e884'; } /* '' */
.icon-add-user:before { content: '\e88b'; } /* '' */
.icon-copy:before { content: '\e88c'; } /* '' */
.icon-pending_actions:before { content: '\e8ca'; } /* '' */
.icon-add_location_alt:before { content: '\e8cb'; } /* '' */
.icon-settings:before { content: '\e8d2'; } /* '' */
.icon-resume:before { content: '\e928'; } /* '' */
