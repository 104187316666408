// Import the global theming stuff
@import '~@angular/material/theming';

// Import our Rain Bird colour palettes file!
@import "../../styles/settings/RBCC-palettes";


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
// Note: These are not hex colours, but rather the full palette in an object, unless the optional arguments are used to limit it
$RBCC-primary: mat-palette($RBCC-green);
$RBCC-secondary:	mat-palette($RBCC-lightgreen);
$RBCC-accent:	mat-palette($RBCC-orange);
$RBCC-background: mat-palette($RBCC-grey, 300, 100, 400);
// The warn palette is optional (defaults to red).
$RBCC-warn: mat-palette($mat-red);
$RBCC-grey-palette: mat-palette($RBCC-grey);

// SASS colour variables
$primary: mat-color($RBCC-primary);
$secondary: mat-color($RBCC-secondary);
$accent: mat-color($RBCC-accent);
$warn: mat-color($RBCC-warn);
$lightestGrey: #f1f1f1;
$lightGrey: mat-color($RBCC-background, lighter);
$mediumGrey: mat-color($RBCC-background);
$darkGrey: mat-color($RBCC-background, darker);
$labelGrey: rgba(0, 0, 0, 0.54);
$lightShadow: rgba(0, 0, 0, 0.1);
$lighterShadow: rgba(0, 0, 0, 0.035);
$background: #6A6A6A;
$white: #ffffff;
$black: #000000;
$menuGreen: #28a745;
$badge: mat-color($RBCC-accent);
$irrigationBlue: #4a90e2;
$danger: #D0021B;
$dangerHover: #8A0C02;
$dangerDisabled: #FF6B7D;
$dangerClicked: #3C3C3C;
$navbarTextColor: #CCCBCC;
$navbarHoverUnderlineColor: #8A8A8A;
$level2NavbarUnderlineColor: #FFBC10;
$lightBlackTextColor: #292929;
$notificationSuccessColor: #089F6B;
$notificationWarningColor: #FF961B;
$notificationAlertColor: #DA2036;
$notificationActionColor: #8319b6;
$buttonDismissBackgroundColor: #E5E5E5;
$weekDayButtonHover:#0C472E;
$grayBorder: #757575;
// Override for Roboto font throughout codebase, don't forget to use mat- prefix classes
$custom-typography: mat-typography-config(
	$font-family: 'Lato, sans-serif'
);

// overwrite the media variables
$breakpoint-not-small: 'screen and (max-width: 47.9375rem)' !default; //mobile device 767px or less
$breakpoint-medium: 'screen and (min-width: 48rem)' !default; //ipad and desktop
$breakpoint-large: 'screen and (min-width: 64rem)' !default; //desktop

$rb-mobile-chevron-color: $primary;
