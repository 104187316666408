// ----START Add Station Component----
.add-stations-dialog {
	.mat-dialog-content {
		div.ag-body-viewport {
			overflow-x: hidden;
		}
	}

	.rb-dialog {
		width: 100%;

		.rb-dialog--header .mat-dialog-title {
			color: $primary;
		}

		@include for-size('phone', max) {
			min-width: 14rem;
		}

		@include for-size('phone') {
			min-width: 17rem;
		}

		@include for-size('phablet', max) {
			height: 80vh;
		}

		@include for-size('tablet') {
			min-width: 30rem;
		}

		@include for-size('desktop') {
			min-width: 32rem;
		}
	}
}
// ----END Add Station Component----
