/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.header1 {
	color: $white;
	font-size: 1.1rem;
	margin-bottom:0;
	line-height: normal;
}

.header3 {
	color: $primary;
}
