/* ==========================================================================
   RBCC CSS
   ========================================================================== */

/**
 * This is a Sass manifest file. DO NOT ADD CSS TO THIS FILE DIRECTLY.
 * Additional CSS should go into one of the Sass partials, or be placed in
 * component stylesheet.
 *
 * Note: cascade applies to order of imports
 *
 */

@use '@angular/material' as mat;
mat.$theme-ignore-duplication-warnings: true;

@import "../assets/fonts/rb-icon-fonts/css/rb-icon-font.css";
@import "../assets/fonts/rb-icon-fonts/css/animation.css";
//@import "../assets/fonts/rb-icon-fonts-2021/css/rb-icon-font.css";
@import "~@mdi/font/css/materialdesignicons.css";
@import "../assets/fonts/Noto_Sans_JP/NotoSansJP.css";

// Import the ag-grid styles
@import '~ag-grid-community/src/styles/ag-grid';
//@import '~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material';
@import '~ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat';


// ITCSS: SETTINGS
@import '~@angular/material/theming';		// todo: remove - causes duplicate inclusion of theming. Brought in by custom RBCC style sheets.
@import "settings/RBCC-palettes";
@import "settings/RBCC-theme-variables";
@import "settings/RBCC-themes";
@import "settings/RBCC-calc-variables";
@import "settings/RBCC-custom-colors";


// ITCSS: TOOLS
@import "tools/RBCC-modules";


// ITCSS: GENERIC (ELEMENTS/BASE)
@import "generic/RBCC-box-sizing";
@import "generic/RBCC-normalize";
@import "generic/RBCC-reset";
@import "generic/RBCC-material-resets";


// ITCSS: ELEMENTS
@import "elements/RBCC-headings";


// ITCSS: OBJECTS - Grouped reusable chunks
@import "objects/RBCC-layout";


// ITCSS: COMPONENTS - Component-specific styles
@import "components/RBCC-timepicker";
@import "components/RBCC-add-stations";
@import "components/RBCC-add-program-steps";
@import "components/RBCC-quick-programming-dialog";
@import "components/RBCC-reports";
@import "components/RBCC-spinner";
@import "components/RBCC-side-panel";
@import "components/RBCC-top-navbar";
@import "components/RBCC-rb-dialog";
@import "components/RBCC-controller-sync";
@import "components/RBCC-ag-grid";
@import "components/RBCC-icon-menu";
@import "components/RBCC-dashboard";
@import "components/RBCC-mat-drawer";
@import "components/RBCC-buttons";
@import "components/RBCC-modals";
@import "components/RBCC-map";
@import "components/RBCC-flow-alarms";
@import "components/RBCC-programs";
@import "components/RBCC-controllers";
@import "components/RBCC-course-viewer";
@import "components/RBCC-activity-tab";
@import "components/RBCC-snackbar";
// ITCSS: UTILITIES
@import "~tachyons-sass/tachyons.scss";
@import "utilities/RBCC-custom-utils";


// Playground highlights
@import '~prismjs/themes/prism.css';

// This include is require for the custom font override
@include mat-core($custom-typography);

// Refactor Styles
@import "./refactor";
@import "./flat-ui";

// Animations
@import "./animations.scss";

// Language specific overrides. This @Import should be last!
@import "./language-overrides";

// TODO: Angular Migration - No longer required to import (included automagically).
// Full Calendar css
//@import '~@fullcalendar/core/main.css';
//@import '~@fullcalendar/daygrid/main.css';
