
// ======================================================================================
// Scroll text loop
// ======================================================================================

.scroll-text-loop > *:first-child {
    animation-name: scroll-text-loop;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

// Animation that makes the text slide out to the left and in from the right, then repeats
// * Using opacity property since `visibility: hidden` doesn't work as expected on Safari
@keyframes scroll-text-loop {

    // Starting slide-out animation from 50% animation progress to simulate a delay at the default state
    50% {
        transform: translateX(0%);
    }

    // Opacity goes from 100% to 0% immediately (from 73% anim progress to 74%) to prevent
    // the text from fading out while sliding out
    73% {
        opacity: 100%;  
    }

    // Text is now completely out of sight (the parent has `overflow: hidden`) and invisible
    // Opacity is 0% to hide the text while it moves to the right
    74% {
        transform: translateX(-100%);
        opacity: 0%;
    }

    // Text is still out of sight but on the right side of the default position
    // Keep opacity at 0% so it doesn't fade in while it moves from the left
    75% {
        transform: translateX(100%);
        opacity: 0%;
    }

    // Make the text completely visible before it slides in from the right
    76% {
        opacity: 100%;
    }

    // Text is now at default position
    100% {
        transform: translateX(0%);
    }

}