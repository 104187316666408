@import '../../styles/settings/RBCC-calc-variables';

// ----START Mat-Drawer Styling----
.tri-pane-container {
	@include for-size('tablet') {
		display: block;
	}

	.mat-drawer,
	.mat-drawer.right-pane {
		width: 100%;

		// drawer inside drawer
		.mat-drawer {
			top: 0;
			bottom: 56px;

			&.fullHeight {
				bottom: 0;
			}
		}

		@include for-size('tablet') {
			top: 0;
			bottom: 56px;
			width: 21rem;
		}
	}
}

.rb-contacts-tab .mat-drawer {
	@include for-size('tablet') {
		height: calc(100vh - #{$tp-vertical-drawer-offset});
	}
}

// To fix issues where overlay dialogs render properly on Safari
html[browser-name='Safari']
{
	.rb-tri-pane {
		.tri-pane-container {
			overflow: visible;

			mat-drawer.right-pane {
				overflow: visible;

				.mat-drawer-inner-container {
					overflow: visible;

					.sub-dialog-displayed {
						.rb-dialog-embedded {
							overflow: visible;

							.rb-dialog-content {
								overflow: visible;

								.mat-drawer-container {
									overflow: visible;

									.mat-drawer-content {
										overflow: visible;
									}
								}
							}

							.rb-dialog-footer {
								display: none;
							}
						}
					}
				}
			}

			.left-pane {

				overflow: visible;

				.sub-dialog-displayed {
					.rb-dialog-embedded {
						overflow: visible;

						.rb-dialog-content {
							overflow: visible;
						}
					}
				}
			}
		}
	}

	.rb-dialog.sub-dialog-displayed {
		mat-dialog-content.rb-dialog--content {
			overflow: visible;
		}
	}
}
// ----END Mat-Drawer Styling----
