// ----START SnackBar----
.snackBar li {
	background-color: $darkGrey;
	transform: translateY(0%);
}
// ----END SnackBar----


// ----START Quick Programming Component----
.quick-programming-dialog .rb-table-wrapper {
	.rb-table-wrapper-grid-container {
		height: inherit;

		.ag-grid-angular {
			height: auto;

			.runtime {
				color: $primary;
			}
		}
	}
}

.quick-programming-dialog .label {
	width: 6.875rem;
}
// ----END Quick Programming Component----


// ----START Golf Add stations dialog----
.mat-checkbox--green .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame,
.mat-radio-button--green .mat-radio-outer-circle {
	border-color: #118559;
}

.showHolesMenu .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.showHolesMenu .mat-checkbox-checked.mat-primary .mat-checkbox-background,
.showAreasMenu .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.showAreasMenu .mat-checkbox-checked.mat-primary .mat-checkbox-background {
	background-color: #212121;
}

.ul--holes {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: .50rem;
}

.li--holes {
	&:nth-child(9) {
		margin-bottom: 1.250rem;
	}
}
// ----END Golf Add stations dialog----

// ----START Full Screen Dialog----
.full-screen-dialog {
	//width: calc(100vw);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
	background-color: white;

	.header {
		min-height: 50px;
		padding: 10px;
		font-size: 1.5rem;
	}

	.main-body {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 10px;
		overflow: auto;
	}

	.button-area {
		display: flex;
		height: 40px;
		box-shadow: 0 1px 5px lightgrey;

		button {
			height: 40px;
		}
	}
}
// ----END Full Screen Dialog ----

// =============================================================================================================================================================
// Station Program Search Modal
// =============================================================================================================================================================
.rb-station-diagnostic,
.rb-station-search {
	.ag-grid-container {
		height: 100%;

		.center {
			height: 100%;
		}

        .rb-table-wrapper-grid-container {
            height: 100%;
        }
	}

	&.isMobile {
		.mat-dialog-title {
			font-size: 16px;
		}
	}
}

.weather-totals-modal {
	.mat-dialog-container {
    box-shadow: none;
		padding: 0;
    border: 2px solid #ccc;
	}
}

